type Record = {
  country: string;
  capital: string;
  capitalReds: string[];
  countryReds: string[];
  flagPath: string;
};

export const europeRecordsArray: Record[] = [
  {
    country: "Albania",
    capital: "Tirana",
    capitalReds: ["Durres", "Vlore", "Kamez", "Fier", "Shkoder"],
    countryReds: ["Switzerland", "Latvia", "Netherlands", "Libya", "Qatar"],
    flagPath: "Albania.png",
  },
  {
    country: "Andorra",
    capital: "Andorra la Vella",
    capitalReds: [
      "Escaldes-Engordany",
      "Encamp",
      "La Massana",
      "Sant Julia de Loria",
      "Ordino",
    ],
    countryReds: ["Slovakia", "Denmark", "Moldova", "Kiribati", "Somalia"],
    flagPath: "Andorra.png",
  },
  {
    country: "Austria",
    capital: "Vienna",
    capitalReds: ["Graz", "Linz", "Salzburg", "Innsbruck", "Klagenfurt"],
    countryReds: [
      "Iceland",
      "Poland",
      "Norway",
      "Papua New Guinea",
      "Botswana",
    ],
    flagPath: "Austria.png",
  },
  {
    country: "Belarus",
    capital: "Minsk",
    capitalReds: ["Homyel'", "Vitsyebsk", "Mahilyow", "Hrodna", "Brest"],
    countryReds: [
      "Andorra",
      "United Kingdom",
      "Slovakia",
      "Sierra Leone",
      "Nigeria",
    ],
    flagPath: "Belarus.png",
  },
  {
    country: "Belgium",
    capital: "Brussels",
    capitalReds: ["Antwerp", "Gent", "Charleroi", "Liege", "Schaarbeek"],
    countryReds: ["Andorra", "Russia", "Slovakia", "Paraguay", "Cambodia"],
    flagPath: "Belgium.png",
  },
  {
    country: "Bosnia and Herzegovina",
    capital: "Sarajevo",
    capitalReds: ["Banja Luka", "Mostar", "Bijeljina", "Tuzla", "Prijedor"],
    countryReds: [
      "France",
      "Latvia",
      "Albania",
      "Saint Kitts and Nevis",
      "Mexico",
    ],
    flagPath: "Bosnia_and_Herzegovina.png",
  },
  {
    country: "Bulgaria",
    capital: "Sofia",
    capitalReds: ["Plovdiv", "Varna", "Burgas", "Ruse", "Stara Zagora"],
    countryReds: ["Hungary", "Belgium", "Serbia", "Maldives", "Nepal"],
    flagPath: "Bulgaria.png",
  },
  {
    country: "Croatia",
    capital: "Zagreb",
    capitalReds: ["Split", "Rijeka", "Osijek", "Zadar", "Slavonski Brod"],
    countryReds: ["Luxembourg", "Spain", "Austria", "Nigeria", "Chile"],
    flagPath: "Croatia.png",
  },
  {
    country: "Czechia",
    capital: "Prague",
    capitalReds: ["Brno", "Ostrava", "Plzen", "Liberec", "Olomouc"],
    countryReds: ["Finland", "Germany", "Belarus", "Jamaica", "Brunei"],
    flagPath: "Czechia.png",
  },
  {
    country: "Denmark",
    capital: "Copenhagen",
    capitalReds: ["Aarhus", "Odense", "Aalborg", "Esbjerg", "Vejle"],
    countryReds: ["Croatia", "North Macedonia", "Moldova", "Kenya", "Armenia"],
    flagPath: "Denmark.png",
  },
  {
    country: "Estonia",
    capital: "Tallinn",
    capitalReds: ["Tartu", "Parnu", "Viljandi", "Rakvere", "Kuressaare"],
    countryReds: ["Croatia", "Austria", "Iceland", "Mongolia", "Namibia"],
    flagPath: "Estonia.png",
  },
  {
    country: "Finland",
    capital: "Helsinki",
    capitalReds: ["Espoo", "Tampere", "Vantaa", "Oulu", "Turku"],
    countryReds: [
      "Croatia",
      "Andorra",
      "San Marino",
      "Bangladesh",
      "Kazakhstan",
    ],
    flagPath: "Finland.png",
  },
  {
    country: "France",
    capital: "Paris",
    capitalReds: ["Nice", "Toulouse", "Marseille", "Rennes", "Grenoble"],
    countryReds: ["Romania", "Denmark", "Poland", "Nicaragua", "Mauritius"],
    flagPath: "France.png",
  },
  {
    country: "Germany",
    capital: "Berlin",
    capitalReds: ["Hamburg", "Munich", "Cologne", "Frankfurt", "Bremen"],
    countryReds: ["Bulgaria", "Luxembourg", "United Kingdom", "Peru", "Belize"],
    flagPath: "Germany.png",
  },
  {
    country: "Greece",
    capital: "Athens",
    capitalReds: ["Piraeus", "Thessaloniki", "Patra", "Peristeri", "Larisa"],
    countryReds: [
      "United Kingdom",
      "Spain",
      "Switzerland",
      "Australia",
      "Malawi",
    ],
    flagPath: "Greece.png",
  },
  {
    country: "Hungary",
    capital: "Budapest",
    capitalReds: ["Debrecen", "Szeged", "Miskolc", "Pecs", "Gyor"],
    countryReds: [
      "Ukraine",
      "Belarus",
      "San Marino",
      "United Arab Emirates",
      "Tanzania",
    ],
    flagPath: "Hungary.png",
  },
  {
    country: "Iceland",
    capital: "Reykjavik",
    capitalReds: [
      "Kopavogur",
      "Hafnarfjordhur",
      "Akureyri",
      "Keflavik",
      "Gardhabaer",
    ],
    countryReds: [
      "Belgium",
      "United Kingdom",
      "Estonia",
      "India",
      "United States of America",
    ],
    flagPath: "Iceland.png",
  },
  {
    country: "Ireland",
    capital: "Dublin",
    capitalReds: ["Cork", "Galway", "Limerick", "Waterford", "Drogheda"],
    countryReds: [
      "Italy",
      "North Macedonia",
      "France",
      "Tanzania",
      "Central African Republic",
    ],
    flagPath: "Ireland.png",
  },
  {
    country: "Italy",
    capital: "Rome",
    capitalReds: ["Milan", "Naples", "Turin", "Palermo", "Genoa"],
    countryReds: ["Spain", "Belarus", "Belgium", "Nigeria", "Dominica"],
    flagPath: "Italy.png",
  },
  {
    country: "Latvia",
    capital: "Riga",
    capitalReds: ["Daugavpils", "Liepaja", "Jelgava", "Jurmala", "Ventspils"],
    countryReds: ["Austria", "Italy", "Malta", "Malaysia", "Nauru"],
    flagPath: "Latvia.png",
  },
  {
    country: "Liechtenstein",
    capital: "Vaduz",
    capitalReds: ["Schaan", "Mauren", "Eschen", "Schellenberg", "Ruggell"],
    countryReds: [
      "Italy",
      "Greece",
      "Sweden",
      "Democratic Republic of the Congo",
      "Uruguay",
    ],
    flagPath: "Liechtenstein.png",
  },
  {
    country: "Lithuania",
    capital: "Vilnius",
    capitalReds: ["Kaunas", "Klaipeda", "Siauliai", "Panevezys", "Alytus"],
    countryReds: ["Czechia", "Ireland", "Andorra", "Kazakhstan", "Ethiopia"],
    flagPath: "Lithuania.png",
  },
  {
    country: "Luxembourg",
    capital: "Luxembourg",
    capitalReds: [
      "Esch-sur-Alzette",
      "Diekirch",
      "Echternach",
      "Remich",
      "Grevenmacher",
    ],
    countryReds: [
      "Spain",
      "Sweden",
      "Bosnia and Herzegovina",
      "Saint Kitts and Nevis",
      "Nauru",
    ],
    flagPath: "Luxembourg.png",
  },
  {
    country: "Malta",
    capital: "Valletta",
    capitalReds: ["Birkirkara", "Qormi", "Sliema", "Fgura", "Hamrun"],
    countryReds: ["Lithuania", "Ireland", "Finland", "Jamaica", "Burundi"],
    flagPath: "Malta.png",
  },
  {
    country: "Moldova",
    capital: "Chisinau",
    capitalReds: ["Tiraspol", "Balti", "Bender", "Ungheni", "Cahul"],
    countryReds: ["Slovakia", "Malta", "Slovenia", "Papua New Guinea", "Sudan"],
    flagPath: "Moldova.png",
  },
  {
    country: "Monaco",
    capital: "Monaco",
    capitalReds: [
      "La Condamine",
      "Fontvieille",
      "Monte Carlo",
      "Moneghetti",
      "Rabaet",
    ],
    countryReds: ["Ukraine", "Russia", "North Macedonia", "Belize", "Uruguay"],
    flagPath: "Monaco.png",
  },
  {
    country: "Montenegro",
    capital: "Podgorica",
    capitalReds: ["Pljevlja", "Ulcinj", "Budva", "Herceg Novi", "Cetinje"],
    countryReds: ["Greece", "Slovenia", "Slovakia", "Dominica", "Bahamas"],
    flagPath: "Montenegro.png",
  },
  {
    country: "Netherlands",
    capital: "Amsterdam",
    capitalReds: ["The Hague", "Rotterdam", "Utrecht", "Eindhoven", "Haarlem"],
    countryReds: ["Croatia", "Greece", "Belgium", "Iran", "Cuba"],
    flagPath: "Netherlands.png",
  },
  {
    country: "North Macedonia",
    capital: "Skopje",
    capitalReds: ["Tetovo", "Bitola", "Kumanovo", "Prilep", "Ohrid"],
    countryReds: ["Montenegro", "Austria", "Moldova", "Libya", "Jamaica"],
    flagPath: "North_Macedonia.png",
  },
  {
    country: "Norway",
    capital: "Oslo",
    capitalReds: ["Bergen", "Trondheim", "Stavanger", "Sorum", "Skien"],
    countryReds: ["Malta", "Monaco", "Slovakia", "Chile", "Tunisia"],
    flagPath: "Norway.png",
  },
  {
    country: "Poland",
    capital: "Warsaw",
    capitalReds: ["Krakow", "Lodz", "Wroclaw", "Poznan", "Gdansk"],
    countryReds: ["Latvia", "Luxembourg", "Norway", "Azerbaijan", "Ethiopia"],
    flagPath: "Poland.png",
  },
  {
    country: "Portugal",
    capital: "Lisbon",
    capitalReds: [
      "Vila Nova de Gaia",
      "Porto",
      "Amadora",
      "Braga",
      "Matosinhos",
    ],
    countryReds: [
      "Spain",
      "Belarus",
      "Bosnia and Herzegovina",
      "Malaysia",
      "Angola",
    ],
    flagPath: "Portugal.png",
  },
  {
    country: "Romania",
    capital: "Bucharest",
    capitalReds: ["Cluj-Napoca", "Timisoara", "Iasi", "Brasov", "Constanta"],
    countryReds: [
      "Lithuania",
      "Portugal",
      "France",
      "Tajikistan",
      "North Korea",
    ],
    flagPath: "Romania.png",
  },
  {
    country: "Russia",
    capital: "Moscow",
    capitalReds: [
      "Saint Petersburg",
      "Novosibirsk",
      "Yekaterinburg",
      "Nizhniy Novgorod",
      "Kazan",
    ],
    countryReds: [
      "United Kingdom",
      "Serbia",
      "Estonia",
      "Botswana",
      "Kyrgyzstan",
    ],
    flagPath: "Russia.png",
  },
  {
    country: "San Marino",
    capital: "San Marino",
    capitalReds: [
      "Serravalle",
      "Borgo Maggiore",
      "Domagnano",
      "Acquaviva",
      "Fiorentino",
    ],
    countryReds: ["Greece", "Serbia", "Ukraine", "Honduras", "Uganda"],
    flagPath: "San_Marino.png",
  },
  {
    country: "Serbia",
    capital: "Belgrade",
    capitalReds: ["Novi Sad", "Nis", "Zemun", "Kragujevac", "Subotica"],
    countryReds: ["Croatia", "Bulgaria", "Ukraine", "Benin", "Kiribati"],
    flagPath: "Serbia.png",
  },
  {
    country: "Slovakia",
    capital: "Bratislava",
    capitalReds: ["Kosice", "Petrzalka", "Presov", "Zilina", "Banska Bystrica"],
    countryReds: [
      "Ukraine",
      "Vatican City",
      "North Macedonia",
      "Palau",
      "Armenia",
    ],
    flagPath: "Slovakia.png",
  },
  {
    country: "Slovenia",
    capital: "Ljubljana",
    capitalReds: ["Maribor", "Kranj", "Celje", "Koper", "Velenje"],
    countryReds: ["Greece", "Russia", "France", "Saudi Arabia", "Ecuador"],
    flagPath: "Slovenia.png",
  },
  {
    country: "Spain",
    capital: "Madrid",
    capitalReds: ["Barcelona", "Sevilla", "Valencia", "Zaragoza", "Malaga"],
    countryReds: ["Slovakia", "San Marino", "Norway", "Kyrgyzstan", "Uganda"],
    flagPath: "Spain.png",
  },
  {
    country: "Sweden",
    capital: "Stockholm",
    capitalReds: ["Gothenburg", "Malmo", "Uppsala", "Halmstad", "Vasteras"],
    countryReds: ["Ukraine", "San Marino", "Romania", "Gabon", "Pakistan"],
    flagPath: "Sweden.png",
  },
  {
    country: "Switzerland",
    capital: "Bern",
    capitalReds: ["Zurich", "Geneva", "Basel", "Lausanne", "Winterthur"],
    countryReds: ["Norway", "San Marino", "Italy", "Honduras", "Palau"],
    flagPath: "Switzerland.png",
  },
  {
    country: "Ukraine",
    capital: "Kyiv",
    capitalReds: ["Kharkiv", "Odesa", "Dnipro", "Donetsk", "Zaporizhzhia"],
    countryReds: [
      "Bosnia and Herzegovina",
      "Bulgaria",
      "Spain",
      "Burkina Faso",
      "Cambodia",
    ],
    flagPath: "Ukraine.png",
  },
  {
    country: "United Kingdom",
    capital: "London",
    capitalReds: ["Birmingham", "Manchester", "Leeds", "Newcastle", "Birstall"],
    countryReds: ["Germany", "Ukraine", "Andorra", "Cameroon", "Burkina Faso"],
    flagPath: "United_Kingdom.png",
  },
  {
    country: "Vatican City",
    capital: "Vatican City",
    capitalReds: [
      "Rome",
      "Cathedral Town",
      "Vaticiano",
      "Athens",
      "Luce Dorma",
    ],
    countryReds: ["Malta", "Iceland", "Belgium", "Guyana", "Comoros"],
    flagPath: "Vatican_City.png",
  },
];

export const asiaRecordsArray: Record[] = [
  {
    country: "Afghanistan",
    capital: "Kabul",
    capitalReds: ["Kandahar", "Herat", "Mazar-e Sharif", "Kunduz", "Jalalabad"],
    countryReds: [
      "Iraq",
      "Georgia",
      "State of Palestine",
      "Dominican Republic",
      "Barbados",
    ],
    flagPath: "Afghanistan.png",
  },
  {
    country: "Armenia",
    capital: "Yerevan",
    capitalReds: ["Gyumri", "Vanadzor", "Kapan", "Armavir", "Artashat"],
    countryReds: [
      "Iraq",
      "Timor-Leste",
      "Bahrain",
      "Slovenia",
      "United States of America",
    ],
    flagPath: "Armenia.png",
  },
  {
    country: "Azerbaijan",
    capital: "Baku",
    capitalReds: ["Sumqayit", "Ganca", "Mingacevir", "Xirdalan", "Naxcivan"],
    countryReds: ["Yemen", "Qatar", "Myanmar", "Tuvalu", "Austria"],
    flagPath: "Azerbaijan.png",
  },
  {
    country: "Bahrain",
    capital: "Manama",
    capitalReds: [
      "Al Muharraq",
      "Madinat Hamad",
      "Madinat 'Isa",
      "Nili",
      "Bazarak",
    ],
    countryReds: ["Yemen", "Thailand", "Cambodia", "Albania", "Croatia"],
    flagPath: "Bahrain.png",
  },
  {
    country: "Bangladesh",
    capital: "Dhaka",
    capitalReds: ["Chattogram", "Khulna", "Sylhet", "Rajshahi", "Comilla"],
    countryReds: ["Kuwait", "Turkey", "Sri Lanka", "Paraguay", "Peru"],
    flagPath: "Bangladesh.png",
  },
  {
    country: "Bhutan",
    capital: "Thimphu",
    capitalReds: ["Paro", "Wangdue Phodrang", "Punakha", "Tsirang", "Mongar"],
    countryReds: [
      "South Korea",
      "Turkmenistan",
      "Uzbekistan",
      "North Macedonia",
      "Denmark",
    ],
    flagPath: "Bhutan.png",
  },
  {
    country: "Brunei",
    capital: "Bandar Seri Begawan",
    capitalReds: ["Kuala Belait", "Tutong", "Bangar", "Seria", "Khulm"],
    countryReds: ["Qatar", "Laos", "Malaysia", "France", "Nigeria"],
    flagPath: "Brunei.png",
  },
  {
    country: "Cambodia",
    capital: "Phnom Penh",
    capitalReds: [
      "Siem Reap",
      "Battambang",
      "Kampong Cham",
      "Prey Veng",
      "Kratie",
    ],
    countryReds: ["Syria", "Lebanon", "Kuwait", "Namibia", "Seychelles"],
    flagPath: "Cambodia.png",
  },
  {
    country: "China",
    capital: "Beijing",
    capitalReds: ["Guangzhou", "Shanghai", "Shenzhen", "Nanyang", "Chengdu"],
    countryReds: ["Lebanon", "Malaysia", "Japan", "Vanuatu", "Belize"],
    flagPath: "China.png",
  },
  {
    country: "Cyprus",
    capital: "Nicosia",
    capitalReds: ["Limassol", "Larnaca", "Famagusta", "Paphos", "Kyrenia"],
    countryReds: [
      "Philippines",
      "Saudi Arabia",
      "Bangladesh",
      "Senegal",
      "Algeria",
    ],
    flagPath: "Cyprus.png",
  },
  {
    country: "Georgia",
    capital: "Tbilisi",
    capitalReds: ["Batumi", "Kutaisi", "Rustavi", "Sokhumi", "Gori"],
    countryReds: [
      "Sri Lanka",
      "South Korea",
      "Vietnam",
      "Vanuatu",
      "South Africa",
    ],
    flagPath: "Georgia.png",
  },
  {
    country: "India",
    capital: "New Delhi",
    capitalReds: ["Mumbai", "Kolkata", "Bangalore", "Chennai", "Hyderabad"],
    countryReds: ["Myanmar", "Malaysia", "Kyrgyzstan", "Colombia", "Tuvalu"],
    flagPath: "India.png",
  },
  {
    country: "Indonesia",
    capital: "Jakarta",
    capitalReds: ["Surabaya", "Bandung", "Bekasi", "Tangerang", "Medan"],
    countryReds: ["Turkmenistan", "Uzbekistan", "Malaysia", "Morocco", "Ghana"],
    flagPath: "Indonesia.png",
  },
  {
    country: "Iran",
    capital: "Tehran",
    capitalReds: ["Mashhad", "Esfahan", "Karaj", "Tabriz", "Shiraz"],
    countryReds: [
      "Kazakhstan",
      "Saudi Arabia",
      "Thailand",
      "Seychelles",
      "Hungary",
    ],
    flagPath: "Iran.png",
  },
  {
    country: "Iraq",
    capital: "Baghdad",
    capitalReds: ["Mosul", "Al Basrah", "Kirkuk", "Erbil", "An Najaf"],
    countryReds: ["North Korea", "Laos", "Mongolia", "Tanzania", "Uganda"],
    flagPath: "Iraq.png",
  },
  {
    country: "Israel",
    capital: "Jerusalem",
    capitalReds: [
      "Tel Aviv-Yafo",
      "Haifa",
      "Rishon LeZiyyon",
      "Petah Tiqwa",
      "Ashdod",
    ],
    countryReds: ["Iraq", "Kuwait", "Cambodia", "Venezuela", "Mexico"],
    flagPath: "Israel.png",
  },
  {
    country: "Japan",
    capital: "Tokyo",
    capitalReds: ["Osaka", "Nagoya", "Yokohama", "Fukuoka", "Sapporo"],
    countryReds: [
      "Bangladesh",
      "Syria",
      "Yemen",
      "South Sudan",
      "United States of America",
    ],
    flagPath: "Japan.png",
  },
  {
    country: "Jordan",
    capital: "Amman",
    capitalReds: ["Irbid", "Az Zarqa'", "Al `Aqabah", "As Salt", "Al Mafraq"],
    countryReds: ["Turkmenistan", "Timor-Leste", "Sri Lanka", "France", "Peru"],
    flagPath: "Jordan.png",
  },
  {
    country: "Kazakhstan",
    capital: "Nur-Sultan",
    capitalReds: ["Almaty", "Shymkent", "Qaraghandy", "Taraz", "Aqtobe"],
    countryReds: [
      "Iraq",
      "State of Palestine",
      "Timor-Leste",
      "Antigua and Barbuda",
      "Slovakia",
    ],
    flagPath: "Kazakhstan.png",
  },
  {
    country: "Kuwait",
    capital: "Kuwait City",
    capitalReds: ["Al Jahra'", "Al Ahmadi", "Parun", "Balkh", "Baghlan"],
    countryReds: [
      "State of Palestine",
      "Azerbaijan",
      "Armenia",
      "Venezuela",
      "Estonia",
    ],
    flagPath: "Kuwait.png",
  },
  {
    country: "Kyrgyzstan",
    capital: "Bishkek",
    capitalReds: ["Osh", "Jalal-Abad", "Karakol", "Talas", "Naryn"],
    countryReds: [
      "Bangladesh",
      "Japan",
      "State of Palestine",
      "Libya",
      "Guinea",
    ],
    flagPath: "Kyrgyzstan.png",
  },
  {
    country: "Laos",
    capital: "Vientiane",
    capitalReds: [
      "Savannakhet",
      "Pakxe",
      "Thakhek",
      "Louangphabang",
      "Xam Nua",
    ],
    countryReds: [
      "United Arab Emirates",
      "Bhutan",
      "Uzbekistan",
      "Trinidad and Tobago",
      "Angola",
    ],
    flagPath: "Laos.png",
  },
  {
    country: "Lebanon",
    capital: "Beirut",
    capitalReds: ["Tripoli", "Sidon", "Tyre", "Zahle", "Jounie"],
    countryReds: ["Oman", "Vietnam", "Cyprus", "Serbia", "Samoa"],
    flagPath: "Lebanon.png",
  },
  {
    country: "Malaysia",
    capital: "Kuala Lumpur",
    capitalReds: [
      "Klang",
      "Ipoh",
      "Butterworth",
      "George Town",
      "Petaling Jaya",
    ],
    countryReds: ["Iran", "Israel", "Azerbaijan", "Belize", "Benin"],
    flagPath: "Malaysia.png",
  },
  {
    country: "Maldives",
    capital: "Male",
    capitalReds: [
      "Un'goofaaru",
      "Naifaru",
      "Mahibadhoo",
      "Eydhafushi",
      "Thinadhoo",
    ],
    countryReds: [
      "State of Palestine",
      "Indonesia",
      "Philippines",
      "Samoa",
      "Niger",
    ],
    flagPath: "Maldives.png",
  },
  {
    country: "Mongolia",
    capital: "Ulaanbaatar",
    capitalReds: ["Erdenet", "Darhan", "Choybalsan", "Moron", "Olgiy"],
    countryReds: ["India", "Qatar", "Nepal", "Comoros", "Nicaragua"],
    flagPath: "Mongolia.png",
  },
  {
    country: "Myanmar",
    capital: "Naypyidaw",
    capitalReds: ["Rangoon", "Mandalay", "Hpa-An", "Bago", "Myeik"],
    countryReds: [
      "State of Palestine",
      "Mongolia",
      "Bangladesh",
      "Zimbabwe",
      "Liechtenstein",
    ],
    flagPath: "Myanmar.png",
  },
  {
    country: "Nepal",
    capital: "Kathmandu",
    capitalReds: ["Pokhara", "Jitpur", "Biratnagar", "Bharatpur", "Birganj"],
    countryReds: ["Afghanistan", "Laos", "Mongolia", "Argentina", "Ecuador"],
    flagPath: "Nepal.png",
  },
  {
    country: "North Korea",
    capital: "Pyongyang",
    capitalReds: ["Hungnam", "Ch'ongjin", "Hamhung", "Sunch'on", "Wonsan"],
    countryReds: ["Laos", "Iran", "Bangladesh", "Eswatini", "Albania"],
    flagPath: "North_Korea.png",
  },
  {
    country: "Oman",
    capital: "Muscat",
    capitalReds: [
      "As Sib",
      "Salalah",
      "As Suwayhirah as Sahil",
      "Ibri",
      "Nizwa",
    ],
    countryReds: [
      "Afghanistan",
      "Thailand",
      "Vietnam",
      "Cameroon",
      "Bosnia and Herzegovina",
    ],
    flagPath: "Oman.png",
  },
  {
    country: "Pakistan",
    capital: "Islamabad",
    capitalReds: [
      "Lahore",
      "Sialkot City",
      "Faisalabad",
      "Rawalpindi",
      "Peshawar",
    ],
    countryReds: [
      "Philippines",
      "Thailand",
      "Saudi Arabia",
      "Jamaica",
      "Vatican City",
    ],
    flagPath: "Pakistan.png",
  },
  {
    country: "State of Palestine",
    capital: "Jerusalem (East)",
    capitalReds: ["Gaza", "Nablus", "Ramallah", "Jenin", "Hebron"],
    countryReds: ["Turkey", "Cyprus", "Singapore", "Croatia", "Libya"],
    flagPath: "State_of_Palestine.png",
  },
  {
    country: "Philippines",
    capital: "Manila",
    capitalReds: [
      "Quezon City",
      "Davao",
      "Caloocan City",
      "Cebu City",
      "Zamboanga City",
    ],
    countryReds: [
      "Sri Lanka",
      "Afghanistan",
      "Georgia",
      "Netherlands",
      "Hungary",
    ],
    flagPath: "Philippines.png",
  },
  {
    country: "Qatar",
    capital: "Doha",
    capitalReds: [
      "Al Wakrah",
      "Ar Rayyan",
      "Al Khawr",
      "Ash Shihaniyah",
      "Umm Salal `Ali",
    ],
    countryReds: ["Cyprus", "Nepal", "Myanmar", "Luxembourg", "Finland"],
    flagPath: "Qatar.png",
  },
  {
    country: "Saudi Arabia",
    capital: "Riyadh",
    capitalReds: ["Jeddah", "Mecca", "Medina", "Ad Dammam", "Al Hufuf"],
    countryReds: ["Uzbekistan", "Mongolia", "Nepal", "Brazil", "Venezuela"],
    flagPath: "Saudi_Arabia.png",
  },
  {
    country: "Singapore",
    capital: "Singapore",
    capitalReds: ["Beirut", "Kuala Lumpur", "Majuro", "Kuantan", "Shah Alam"],
    countryReds: ["Laos", "Kazakhstan", "Turkey", "Montenegro", "Tanzania"],
    flagPath: "Singapore.png",
  },
  {
    country: "South Korea",
    capital: "Seoul",
    capitalReds: ["Busan", "Incheon", "Daegu", "Gwangju", "Daejeon"],
    countryReds: [
      "Kuwait",
      "Saudi Arabia",
      "Kyrgyzstan",
      "Saint Lucia",
      "Cuba",
    ],
    flagPath: "South_Korea.png",
  },
  {
    country: "Sri Lanka",
    capital: "Sri Jayawardenepura Kotte",
    capitalReds: [
      "Colombo",
      "Moratuwa",
      "Negombo",
      "Batticaloa",
      "Anuradhapura",
    ],
    countryReds: [
      "Israel",
      "Philippines",
      "North Korea",
      "North Macedonia",
      "Morocco",
    ],
    flagPath: "Sri_Lanka.png",
  },
  {
    country: "Syria",
    capital: "Damascus",
    capitalReds: ["Aleppo", "Homs", "Latakia", "Hamah", "Dayr az Zawr"],
    countryReds: [
      "Saudi Arabia",
      "Cambodia",
      "Pakistan",
      "Dominican Republic",
      "Mauritius",
    ],
    flagPath: "Syria.png",
  },
  {
    country: "Tajikistan",
    capital: "Dushanbe",
    capitalReds: ["Khujand", "Bokhtar", "Chkalov", "Khorugh", "Kulob"],
    countryReds: [
      "South Korea",
      "Georgia",
      "Iran",
      "Cameroon",
      "Equatorial Guinea",
    ],
    flagPath: "Tajikistan.png",
  },
  {
    country: "Thailand",
    capital: "Bangkok",
    capitalReds: [
      "Nonthaburi",
      "Pak Kret",
      "Hat Yai",
      "Chiang Mai",
      "Nakhon Ratchasima",
    ],
    countryReds: ["China", "India", "Indonesia", "Guinea-Bissau", "Brazil"],
    flagPath: "Thailand.png",
  },
  {
    country: "Timor-Leste",
    capital: "Dili",
    capitalReds: ["Maliana", "Ainaro", "Baucau", "Manatuto", "Gleno"],
    countryReds: ["Georgia", "Myanmar", "Saudi Arabia", "Slovakia", "Ecuador"],
    flagPath: "Timor-Leste.png",
  },
  {
    country: "Turkey",
    capital: "Ankara",
    capitalReds: ["Istanbul", "Izmir", "Bursa", "Antalya", "Konya"],
    countryReds: ["Brunei", "Iran", "Myanmar", "United Kingdom", "Denmark"],
    flagPath: "Turkey.png",
  },
  {
    country: "Turkmenistan",
    capital: "Ashgabat",
    capitalReds: ["Turkmenabat", "Dasoguz", "Mary", "Balkanabat", "Anew"],
    countryReds: [
      "Kuwait",
      "North Korea",
      "India",
      "Liechtenstein",
      "Portugal",
    ],
    flagPath: "Turkmenistan.png",
  },
  {
    country: "United Arab Emirates",
    capital: "Abu Dhabi",
    capitalReds: ["Sharjah", "Dubai", "Al `Ayn", "`Ajman", "Ra's al Khaymah"],
    countryReds: [
      "Indonesia",
      "Iran",
      "Lebanon",
      "Denmark",
      "Equatorial Guinea",
    ],
    flagPath: "United_Arab_Emirates.png",
  },
  {
    country: "Uzbekistan",
    capital: "Tashkent",
    capitalReds: ["Samarkand", "Andijon", "Namangan", "Qo`qon", "Farg`ona"],
    countryReds: [
      "Thailand",
      "Afghanistan",
      "South Korea",
      "Norway",
      "Moldova",
    ],
    flagPath: "Uzbekistan.png",
  },
  {
    country: "Vietnam",
    capital: "Hanoi",
    capitalReds: [
      "Ho Chi Minh City",
      "Haiphong",
      "Can Tho",
      "Bien Hoa",
      "Quang Ha",
    ],
    countryReds: ["Yemen", "Laos", "South Korea", "Ghana", "Latvia"],
    flagPath: "Vietnam.png",
  },
  {
    country: "Yemen",
    capital: "Sana'a",
    capitalReds: ["Ta`izz", "Al Hudaydah", "Aden", "Zinjibar", "Ibb"],
    countryReds: ["Iraq", "Syria", "Timor-Leste", "Czechia", "Serbia"],
    flagPath: "Yemen.png",
  },
];

export const africaRecordsArray: Record[] = [
  {
    country: "Algeria",
    capital: "Algiers",
    capitalReds: ["Oran", "Constantine", "Blida", "Batna", "Setif"],
    countryReds: [
      "Nigeria",
      "Liberia",
      "Central African Republic",
      "Nepal",
      "Cyprus",
    ],
    flagPath: "Algeria.png",
  },
  {
    country: "Angola",
    capital: "Luanda",
    capitalReds: ["Huambo", "Cabinda", "Benguela", "Lobito", "Mocamedes"],
    countryReds: ["Malawi", "Sierra Leone", "Tunisia", "Barbados", "China"],
    flagPath: "Angola.png",
  },
  {
    country: "Benin",
    capital: "Porto-Novo",
    capitalReds: ["Cotonou", "Djougou", "Parakou", "Kandi", "Lokossa"],
    countryReds: [
      "Madagascar",
      "Central African Republic",
      "Comoros",
      "Belarus",
      "Philippines",
    ],
    flagPath: "Benin.png",
  },
  {
    country: "Botswana",
    capital: "Gaborone",
    capitalReds: [
      "Francistown",
      "Molepolole",
      "Selibe Phikwe",
      "Maun",
      "Serowe",
    ],
    countryReds: ["Gambia", "South Africa", "Tunisia", "Luxembourg", "Belarus"],
    flagPath: "Botswana.png",
  },
  {
    country: "Burkina Faso",
    capital: "Ouagadougou",
    capitalReds: [
      "Bobo-Dioulasso",
      "Koudougou",
      "Ouahigouya",
      "Banfora",
      "Kaya",
    ],
    countryReds: [
      "Central African Republic",
      "Mauritius",
      "Liberia",
      "Argentina",
      "Venezuela",
    ],
    flagPath: "Burkina_Faso.png",
  },
  {
    country: "Burundi",
    capital: "Gitega",
    capitalReds: ["Bujumbura", "Ngozi", "Kayanza", "Rutana", "Bubanza"],
    countryReds: ["Algeria", "Angola", "Cabo Verde", "Brazil", "Croatia"],
    flagPath: "Burundi.png",
  },
  {
    country: "Cabo Verde",
    capital: "Praia",
    capitalReds: [
      "Mindelo",
      "Assomada",
      "Pedra Badejo",
      "Sao Filipe",
      "Sal Rei",
    ],
    countryReds: ["Ethiopia", "Angola", "Mali", "Armenia", "Slovenia"],
    flagPath: "Cabo_Verde.png",
  },
  {
    country: "Cameroon",
    capital: "Yaounde",
    capitalReds: ["Douala", "Maroua", "Bafoussam", "Bamenda", "Garoua"],
    countryReds: ["Lesotho", "Mali", "Cabo Verde", "Laos", "Bolivia"],
    flagPath: "Cameroon.png",
  },
  {
    country: "Central African Republic",
    capital: "Bangui",
    capitalReds: ["Bimbo", "Berberati", "Bambari", "Bouar", "Bossangoa"],
    countryReds: ["Guinea-Bissau", "Eswatini", "Angola", "San Marino", "Chile"],
    flagPath: "Central_African_Republic.png",
  },
  {
    country: "Chad",
    capital: "N'Djamena",
    capitalReds: ["Sarh", "Moundou", "Abeche", "Goz-Beida", "Koumra"],
    countryReds: ["Guinea", "Botswana", "Eritrea", "Estonia", "Bhutan"],
    flagPath: "Chad.png",
  },
  {
    country: "Comoros",
    capital: "Moroni",
    capitalReds: ["Mutsamudu", "Fomboni", "Mkazi", "Mitsamiouli", "Makoua"],
    countryReds: [
      "Botswana",
      "Sao Tome and Principe",
      "Liberia",
      "Brunei",
      "United States of America",
    ],
    flagPath: "Comoros.png",
  },
  {
    country: "Democratic Republic of the Congo",
    capital: "Kinshasa",
    capitalReds: ["Kananga", "Lubumbashi", "Mbuji-Mayi", "Kisangani", "Kikwit"],
    countryReds: [
      "Botswana",
      "Gambia",
      "Seychelles",
      "Denmark",
      "Antigua and Barbuda",
    ],
    flagPath: "Democratic_Republic_of_the_Congo.png",
  },
  {
    country: "Republic of the Congo",
    capital: "Brazzaville",
    capitalReds: ["Pointe-Noire", "Dolisie", "Owando", "Ouesso", "Sibiti"],
    countryReds: [
      "Sierra Leone",
      "Egypt",
      "Seychelles",
      "Costa Rica",
      "Mexico",
    ],
    flagPath: "Republic_of_the_Congo.png",
  },
  {
    country: "Cote d'Ivoire",
    capital: "Yamoussoukro",
    capitalReds: ["Abidjan", "Bouake", "Korhogo", "Daloa", "San-Pedro"],
    countryReds: ["Libya", "Kenya", "Sierra Leone", "Norway", "Chile"],
    flagPath: "Cote_d'Ivoire.png",
  },
  {
    country: "Djibouti",
    capital: "Djibouti",
    capitalReds: ["Ali Sabieh", "Dikhil", "Obock", "Tadjourah", "Arta"],
    countryReds: ["Cote d'Ivoire", "Ethiopia", "Chad", "Canada", "El Salvador"],
    flagPath: "Djibouti.png",
  },
  {
    country: "Egypt",
    capital: "Cairo",
    capitalReds: [
      "Alexandria",
      "Giza",
      "Shubra al Khaymah",
      "Halwan",
      "Al Mahallah al Kubra",
    ],
    countryReds: ["Somalia", "Gabon", "Ethiopia", "Mexico", "Afghanistan"],
    flagPath: "Egypt.png",
  },
  {
    country: "Equatorial Guinea",
    capital: "Malabo",
    capitalReds: ["Bata", "Ebebiyin", "Evinayong", "Luba", "Mongomo"],
    countryReds: [
      "Democratic Republic of the Congo",
      "Algeria",
      "Morocco",
      "Mongolia",
      "Papua New Guinea",
    ],
    flagPath: "Equatorial_Guinea.png",
  },
  {
    country: "Eritrea",
    capital: "Asmara",
    capitalReds: ["Keren", "Assab", "Massawa", "Mendefera", "Barentu"],
    countryReds: ["Somalia", "Botswana", "Niger", "Jamaica", "New Zealand"],
    flagPath: "Eritrea.png",
  },
  {
    country: "Eswatini",
    capital: "Mbabane",
    capitalReds: ["Lavumisa", "Manzini", "Siteki", "Nhlangano", "Mhlume"],
    countryReds: ["Nigeria", "Zimbabwe", "Djibouti", "Ecuador", "Armenia"],
    flagPath: "Eswatini.png",
  },
  {
    country: "Ethiopia",
    capital: "Addis Ababa",
    capitalReds: ["Nazret", "Gonder", "Mekele", "Awasa", "Dire Dawa"],
    countryReds: ["Mauritania", "Libya", "Uganda", "Oman", "Georgia"],
    flagPath: "Ethiopia.png",
  },
  {
    country: "Gabon",
    capital: "Libreville",
    capitalReds: ["Port-Gentil", "Oyem", "Franceville", "Lambarene", "Mouila"],
    countryReds: [
      "Ethiopia",
      "Mauritania",
      "Zimbabwe",
      "South Korea",
      "Cyprus",
    ],
    flagPath: "Gabon.png",
  },
  {
    country: "Gambia",
    capital: "Banjul",
    capitalReds: [
      "Brikama",
      "Mansa Konko",
      "Basse Santa Su",
      "Kanifing",
      "Janjanbureh",
    ],
    countryReds: [
      "Cameroon",
      "Zimbabwe",
      "Republic of the Congo",
      "Italy",
      "India",
    ],
    flagPath: "Gambia.png",
  },
  {
    country: "Ghana",
    capital: "Accra",
    capitalReds: ["Kumasi", "Tamale", "Sekondi", "Obuase", "Tema"],
    countryReds: [
      "Zimbabwe",
      "Mali",
      "Sao Tome and Principe",
      "Jordan",
      "San Marino",
    ],
    flagPath: "Ghana.png",
  },
  {
    country: "Guinea",
    capital: "Conakry",
    capitalReds: ["Gueckedou", "N'Zerekore", "Kindia", "Boke", "Kankan"],
    countryReds: ["Equatorial Guinea", "Eritrea", "Togo", "Italy", "Bahrain"],
    flagPath: "Guinea.png",
  },
  {
    country: "Guinea-Bissau",
    capital: "Bissau",
    capitalReds: ["Quinhamel", "Catio", "Bafata", "Gabu", "Cacheu"],
    countryReds: ["Mali", "Ghana", "Lesotho", "Belize", "Slovakia"],
    flagPath: "Guinea-Bissau.png",
  },
  {
    country: "Kenya",
    capital: "Nairobi",
    capitalReds: ["Meru", "Mombasa", "Kisumu", "Nakuru", "Eldoret"],
    countryReds: [
      "Sao Tome and Principe",
      "Republic of the Congo",
      "Mali",
      "Canada",
      "Tonga",
    ],
    flagPath: "Kenya.png",
  },
  {
    country: "Lesotho",
    capital: "Maseru",
    capitalReds: [
      "Teyateyaneng",
      "Mafeteng",
      "Leribe",
      "Mohale's Hoek",
      "Quthing",
    ],
    countryReds: [
      "Liberia",
      "Democratic Republic of the Congo",
      "Guinea",
      "Philippines",
      "Colombia",
    ],
    flagPath: "Lesotho.png",
  },
  {
    country: "Liberia",
    capital: "Monrovia",
    capitalReds: ["Gbarnga", "Kakata", "Harper", "Voinjama", "Zwedru"],
    countryReds: [
      "Libya",
      "Gambia",
      "Central African Republic",
      "Luxembourg",
      "Peru",
    ],
    flagPath: "Liberia.png",
  },
  {
    country: "Libya",
    capital: "Tripoli",
    capitalReds: ["Benghazi", "Misratah", "Al Bayda'", "Az Zawiyah", "Gharyan"],
    countryReds: [
      "Cameroon",
      "Republic of the Congo",
      "Madagascar",
      "India",
      "Israel",
    ],
    flagPath: "Libya.png",
  },
  {
    country: "Madagascar",
    capital: "Antananarivo",
    capitalReds: [
      "Antsirabe",
      "Toamasina",
      "Fianarantsoa",
      "Mahajanga",
      "Toliara",
    ],
    countryReds: ["Comoros", "Libya", "Angola", "South Korea", "Albania"],
    flagPath: "Madagascar.png",
  },
  {
    country: "Malawi",
    capital: "Lilongwe",
    capitalReds: ["Blantyre", "Mzuzu", "Zomba", "Salima", "Nkhotakota"],
    countryReds: [
      "Botswana",
      "Sao Tome and Principe",
      "Cameroon",
      "United Kingdom",
      "Denmark",
    ],
    flagPath: "Malawi.png",
  },
  {
    country: "Mali",
    capital: "Bamako",
    capitalReds: ["Sikasso", "Segou", "Gao", "Mopti", "Koutiala"],
    countryReds: ["Benin", "Namibia", "Eswatini", "Portugal", "Tajikistan"],
    flagPath: "Mali.png",
  },
  {
    country: "Mauritania",
    capital: "Nouakchott",
    capitalReds: ["Nema", "Nouadhibou", "Kiffa", "Zouerate", "Rosso"],
    countryReds: ["Seychelles", "Tunisia", "Cameroon", "Armenia", "Cyprus"],
    flagPath: "Mauritania.png",
  },
  {
    country: "Mauritius",
    capital: "Port Louis",
    capitalReds: [
      "Curepipe",
      "Quartier Militaire",
      "Centre de Flacq",
      "Port Mathurin",
      "Cap Malheureux",
    ],
    countryReds: [
      "Madagascar",
      "Cote d'Ivoire",
      "Cabo Verde",
      "Netherlands",
      "Trinidad and Tobago",
    ],
    flagPath: "Mauritius.png",
  },
  {
    country: "Morocco",
    capital: "Rabat",
    capitalReds: ["Casablanca", "Fes", "Tangier", "Marrakech", "Sale"],
    countryReds: [
      "Libya",
      "Comoros",
      "Mauritania",
      "Solomon Islands",
      "Uzbekistan",
    ],
    flagPath: "Morocco.png",
  },
  {
    country: "Mozambique",
    capital: "Maputo",
    capitalReds: ["Matola", "Nampula", "Beira", "Chimoio", "Cidade de Nacala"],
    countryReds: ["Cabo Verde", "Cameroon", "Zambia", "Vanuatu", "Panama"],
    flagPath: "Mozambique.png",
  },
  {
    country: "Namibia",
    capital: "Windhoek",
    capitalReds: [
      "Rundu",
      "Swakopmund",
      "Katima Mulilo",
      "Otjiwarongo",
      "Rehoboth",
    ],
    countryReds: [
      "Nigeria",
      "Senegal",
      "Guinea",
      "United Arab Emirates",
      "Yemen",
    ],
    flagPath: "Namibia.png",
  },
  {
    country: "Niger",
    capital: "Niamey",
    capitalReds: ["Zinder", "Maradi", "Agadez", "Tahoua", "Dosso"],
    countryReds: [
      "Madagascar",
      "Uganda",
      "Libya",
      "United States of America",
      "Uzbekistan",
    ],
    flagPath: "Niger.png",
  },
  {
    country: "Nigeria",
    capital: "Abuja",
    capitalReds: ["Onitsha", "Kano", "Ibadan", "Owerri", "Lagos"],
    countryReds: ["South Africa", "Zambia", "Tanzania", "Lebanon", "Colombia"],
    flagPath: "Nigeria.png",
  },
  {
    country: "Rwanda",
    capital: "Kigali",
    capitalReds: ["Nyanza", "Gisenyi", "Ruhengeri", "Byumba", "Rwamagana"],
    countryReds: ["Kenya", "Mali", "Algeria", "Malaysia", "Switzerland"],
    flagPath: "Rwanda.png",
  },
  {
    country: "Sao Tome and Principe",
    capital: "Sao Tome",
    capitalReds: ["Trindade", "Guadalupe", "Santana", "Neves", "Santo Antonio"],
    countryReds: ["Liberia", "Tanzania", "Algeria", "Austria", "Thailand"],
    flagPath: "Sao_Tome_and_Principe.png",
  },
  {
    country: "Senegal",
    capital: "Dakar",
    capitalReds: ["Thies", "Rufisque", "Ziguinchor", "Saint-Louis", "Kaolack"],
    countryReds: ["Malawi", "Ethiopia", "Burkina Faso", "Estonia", "Slovenia"],
    flagPath: "Senegal.png",
  },
  {
    country: "Seychelles",
    capital: "Victoria",
    capitalReds: ["Honiara", "Balzar", "La Concordia", "Salinas", "Santa Rosa"],
    countryReds: ["Chad", "Liberia", "Mauritius", "Tuvalu", "Vietnam"],
    flagPath: "Seychelles.png",
  },
  {
    country: "Sierra Leone",
    capital: "Freetown",
    capitalReds: ["Kenema", "Bo", "Makeni", "Port Loko", "Koidu-Bulma"],
    countryReds: [
      "Tunisia",
      "Burundi",
      "Liberia",
      "United States of America",
      "Australia",
    ],
    flagPath: "Sierra_Leone.png",
  },
  {
    country: "Somalia",
    capital: "Mogadishu",
    capitalReds: ["Jamaame", "Baidoa", "Jawhar", "Kismaayo", "Beledweyne"],
    countryReds: [
      "Chad",
      "Cote d'Ivoire",
      "Guinea-Bissau",
      "Argentina",
      "Honduras",
    ],
    flagPath: "Somalia.png",
  },
  {
    country: "South Africa",
    capital: "Pretoria",
    capitalReds: [
      "Vereeniging",
      "Johannesburg",
      "Durban",
      "Welkom",
      "Pietermaritzburg",
    ],
    countryReds: ["Uganda", "Libya", "Nigeria", "Pakistan", "Azerbaijan"],
    flagPath: "South_Africa.png",
  },
  {
    country: "South Sudan",
    capital: "Juba",
    capitalReds: ["Bor", "Yei", "Wau", "Malakal", "Aweil"],
    countryReds: ["Djibouti", "Mauritius", "Niger", "Honduras", "Norway"],
    flagPath: "South_Sudan.png",
  },
  {
    country: "Sudan",
    capital: "Khartoum",
    capitalReds: [
      "Omdurman",
      "Khartoum North",
      "Kassala",
      "Port Sudan",
      "El Obeid",
    ],
    countryReds: [
      "Senegal",
      "Liberia",
      "Cabo Verde",
      "Antigua and Barbuda",
      "Russia",
    ],
    flagPath: "Sudan.png",
  },
  {
    country: "Tanzania",
    capital: "Dodoma",
    capitalReds: ["Dar es Salaam", "Arusha", "Mwanza", "Mbeya", "Tanga"],
    countryReds: ["Botswana", "Somalia", "Burkina Faso", "Armenia", "Canada"],
    flagPath: "Tanzania.png",
  },
  {
    country: "Togo",
    capital: "Lome",
    capitalReds: ["Sokode", "Kpalime", "Atakpame", "Dapaong", "Kara"],
    countryReds: ["Botswana", "Cameroon", "Tunisia", "Paraguay", "Venezuela"],
    flagPath: "Togo.png",
  },
  {
    country: "Tunisia",
    capital: "Tunis",
    capitalReds: ["Sfax", "Sousse", "Gabes", "Zarzis", "Kairouan"],
    countryReds: ["Cabo Verde", "Kenya", "Tanzania", "Turkey", "Montenegro"],
    flagPath: "Tunisia.png",
  },
  {
    country: "Uganda",
    capital: "Kampala",
    capitalReds: ["Mbale", "Arua", "Tororo", "Gulu", "Lira"],
    countryReds: ["Djibouti", "Gambia", "South Africa", "Ukraine", "Armenia"],
    flagPath: "Uganda.png",
  },
  {
    country: "Zambia",
    capital: "Lusaka",
    capitalReds: ["Ndola", "Kitwe", "Kasama", "Kashikishi", "Chingola"],
    countryReds: ["Cabo Verde", "Benin", "Mauritius", "Mexico", "Dominica"],
    flagPath: "Zambia.png",
  },
  {
    country: "Zimbabwe",
    capital: "Harare",
    capitalReds: ["Bulawayo", "Chitungwiza", "Mutare", "Gweru", "Masvingo"],
    countryReds: [
      "Angola",
      "South Africa",
      "Democratic Republic of the Congo",
      "Canada",
      "North Macedonia",
    ],
    flagPath: "Zimbabwe.png",
  },
];

export const northAmericaRecordsArray: Record[] = [
  {
    country: "Antigua and Barbuda",
    capital: "Saint John's",
    capitalReds: ["Loja", "Ibarra", "Tulcan", "Macas", "Port Moresby"],
    countryReds: ["Panama", "Haiti", "Dominica", "Malaysia", "Italy"],
    flagPath: "Antigua_and_Barbuda.png",
  },
  {
    country: "Bahamas",
    capital: "Nassau",
    capitalReds: [
      "Freeport City",
      "Marsh Harbour",
      "Coro",
      "Jackson City",
      "Cabimas",
    ],
    countryReds: ["Cuba", "Mexico", "Belize", "Eswatini", "Andorra"],
    flagPath: "Bahamas.png",
  },
  {
    country: "Barbados",
    capital: "Bridgetown",
    capitalReds: ["La Guaira", "Pando", "Rocha", "Canelones", "Durazno"],
    countryReds: ["Honduras", "Saint Lucia", "Panama", "Latvia", "Thailand"],
    flagPath: "Barbados.png",
  },
  {
    country: "Belize",
    capital: "Belmopan",
    capitalReds: [
      "Belize City",
      "Orange Walk",
      "San Ignacio",
      "Dangriga",
      "Corozal",
    ],
    countryReds: ["El Salvador", "Haiti", "Costa Rica", "Egypt", "Peru"],
    flagPath: "Belize.png",
  },
  {
    country: "Canada",
    capital: "Ottawa",
    capitalReds: ["Montreal", "Vancouver", "Calgary", "Edmonton", "Toronto"],
    countryReds: [
      "El Salvador",
      "Panama",
      "Saint Kitts and Nevis",
      "Netherlands",
      "Uzbekistan",
    ],
    flagPath: "Canada.png",
  },
  {
    country: "Costa Rica",
    capital: "San Jose",
    capitalReds: [
      "Cartago",
      "Puerto Limon",
      "Liberia",
      "Alajuela",
      "Puntarenas",
    ],
    countryReds: [
      "Belize",
      "Nicaragua",
      "Haiti",
      "Papua New Guinea",
      "Botswana",
    ],
    flagPath: "Costa_Rica.png",
  },
  {
    country: "Cuba",
    capital: "Havana",
    capitalReds: [
      "Santiago de Cuba",
      "Holguin",
      "Camaguey",
      "Bayamo",
      "Guantanamo",
    ],
    countryReds: [
      "Costa Rica",
      "Panama",
      "Haiti",
      "Botswana",
      "Central African Republic",
    ],
    flagPath: "Cuba.png",
  },
  {
    country: "Dominica",
    capital: "Roseau",
    capitalReds: ["Azua", "Bani", "Salcedo", "Nagua", "El Seibo"],
    countryReds: [
      "Costa Rica",
      "Nicaragua",
      "Bahamas",
      "Portugal",
      "Guinea-Bissau",
    ],
    flagPath: "Dominica.png",
  },
  {
    country: "Dominican Republic",
    capital: "Santo Domingo",
    capitalReds: [
      "Santiago",
      "La Vega",
      "San Pedro de Macoris",
      "La Romana",
      "San Francisco de Macoris",
    ],
    countryReds: [
      "Honduras",
      "Grenada",
      "El Salvador",
      "Republic of the Congo",
      "Marshall Islands",
    ],
    flagPath: "Dominican_Republic.png",
  },
  {
    country: "El Salvador",
    capital: "San Salvador",
    capitalReds: [
      "Usulutan",
      "Santa Ana",
      "San Miguel",
      "Sonsonate",
      "Santa Tecla",
    ],
    countryReds: ["Dominica", "Cuba", "Grenada", "Ethiopia", "Guinea"],
    flagPath: "El_Salvador.png",
  },
  {
    country: "Grenada",
    capital: "Saint George's",
    capitalReds: ["Monte Plata", "Bavaro", "Pantanal", "Mao", "Novo Selo"],
    countryReds: ["Honduras", "Saint Lucia", "Jamaica", "Niger", "Nauru"],
    flagPath: "Grenada.png",
  },
  {
    country: "Guatemala",
    capital: "Guatemala City",
    capitalReds: [
      "Quetzaltenango",
      "Escuintla",
      "San Luis",
      "Jalapa",
      "Puerto Barrios",
    ],
    countryReds: ["Barbados", "Belize", "Jamaica", "Vietnam", "Tunisia"],
    flagPath: "Guatemala.png",
  },
  {
    country: "Haiti",
    capital: "Port-au-Prince",
    capitalReds: [
      "Carrefour",
      "Petion-Ville",
      "Gonaives",
      "Delmas",
      "Cap-Haitien",
    ],
    countryReds: [
      "Saint Lucia",
      "Cuba",
      "Dominican Republic",
      "Azerbaijan",
      "Czechia",
    ],
    flagPath: "Haiti.png",
  },
  {
    country: "Honduras",
    capital: "Tegucigalpa",
    capitalReds: [
      "Comayaguela",
      "San Pedro Sula",
      "Puerto Cortes",
      "La Ceiba",
      "Villanueva",
    ],
    countryReds: [
      "Cuba",
      "Guatemala",
      "Saint Kitts and Nevis",
      "Gabon",
      "Guinea",
    ],
    flagPath: "Honduras.png",
  },
  {
    country: "Jamaica",
    capital: "Kingston",
    capitalReds: [
      "Portmore",
      "May Pen",
      "Spanish Town",
      "Montego Bay",
      "Half Way Tree",
    ],
    countryReds: [
      "Honduras",
      "Mexico",
      "Saint Lucia",
      "San Marino",
      "Mauritania",
    ],
    flagPath: "Jamaica.png",
  },
  {
    country: "Mexico",
    capital: "Mexico City",
    capitalReds: ["Guadalajara", "Monterrey", "Tijuana", "Ecatepec", "Puebla"],
    countryReds: [
      "Dominica",
      "United States of America",
      "Honduras",
      "Nepal",
      "Tuvalu",
    ],
    flagPath: "Mexico.png",
  },
  {
    country: "Nicaragua",
    capital: "Managua",
    capitalReds: ["Boaco", "Leon", "Matagalpa", "Chinandega", "Masaya"],
    countryReds: [
      "Guatemala",
      "Honduras",
      "Dominican Republic",
      "Cabo Verde",
      "Slovenia",
    ],
    flagPath: "Nicaragua.png",
  },
  {
    country: "Panama",
    capital: "Panama City",
    capitalReds: ["Colon", "David", "La Chorrera", "Chitre", "Santiago"],
    countryReds: [
      "Dominican Republic",
      "Canada",
      "United States of America",
      "Belarus",
      "Cabo Verde",
    ],
    flagPath: "Panama.png",
  },
  {
    country: "Saint Kitts and Nevis",
    capital: "Basseterre",
    capitalReds: ["Kos", "Pyrgos", "Korinthos", "Cape Coast", "Damongo"],
    countryReds: [
      "Cuba",
      "Dominican Republic",
      "Bahamas",
      "North Korea",
      "Myanmar",
    ],
    flagPath: "Saint_Kitts_and_Nevis.png",
  },
  {
    country: "Saint Lucia",
    capital: "Castries",
    capitalReds: [
      "Gros Islet",
      "Saint Stephen's Bay",
      "Port Hillary",
      "Port Rose",
      "Belfort",
    ],
    countryReds: [
      "Barbados",
      "El Salvador",
      "Cuba",
      "Indonesia",
      "Afghanistan",
    ],
    flagPath: "Saint_Lucia.png",
  },
  {
    country: "Saint Vincent and the Grenadines",
    capital: "Kingstown",
    capitalReds: [
      "Le Havre",
      "Besancon",
      "Caen",
      "Port Douglas",
      "Saint Robert's",
    ],
    countryReds: [
      "Antigua and Barbuda",
      "Dominican Republic",
      "Grenada",
      "Ethiopia",
      "Portugal",
    ],
    flagPath: "Saint_Vincent_and_the_Grenadines.png",
  },
  {
    country: "Trinidad and Tobago",
    capital: "Port of Spain",
    capitalReds: ["Chaguanas", "San Fernando", "Couva", "Tunapuna", "Arima"],
    countryReds: ["Saint Lucia", "Honduras", "Bahamas", "Greece", "Indonesia"],
    flagPath: "Trinidad_and_Tobago.png",
  },
  {
    country: "United States of America",
    capital: "Washington D.C.",
    capitalReds: ["Los Angeles", "Chicago", "Miami", "Dallas", "Philadelphia"],
    countryReds: [
      "Saint Lucia",
      "Dominica",
      "Haiti",
      "Marshall Islands",
      "Czechia",
    ],
    flagPath: "United_States_of_America.png",
  },
];

export const southAmericaRecordsArray: Record[] = [
  {
    country: "Argentina",
    capital: "Buenos Aires",
    capitalReds: [
      "Cordoba",
      "Rosario",
      "San Miguel de Tucuman",
      "Mar del Plata",
      "Salta",
    ],
    countryReds: ["Paraguay", "Brazil", "Bolivia", "Jamaica", "Mongolia"],
    flagPath: "Argentina.png",
  },
  {
    country: "Bolivia",
    capital: "La Paz",
    capitalReds: ["Santa Cruz", "Sacaba", "Cochabamba", "Oruro", "Warnes"],
    countryReds: ["Peru", "Argentina", "Ecuador", "Botswana", "Haiti"],
    flagPath: "Bolivia.png",
  },
  {
    country: "Brazil",
    capital: "Brasilia",
    capitalReds: [
      "Rio de Janeiro",
      "Belo Horizonte",
      "Sao Paulo",
      "Salvador",
      "Fortaleza",
    ],
    countryReds: ["Uruguay", "Colombia", "Chile", "Syria", "Mongolia"],
    flagPath: "Brazil.png",
  },
  {
    country: "Chile",
    capital: "Santiago",
    capitalReds: [
      "Antofagasta",
      "Vina del Mar",
      "Valparaiso",
      "Temuco",
      "San Bernardo",
    ],
    countryReds: [
      "Argentina",
      "Suriname",
      "Uruguay",
      "Cyprus",
      "North Macedonia",
    ],
    flagPath: "Chile.png",
  },
  {
    country: "Colombia",
    capital: "Bogota",
    capitalReds: ["Medellin", "Cali", "Barranquilla", "Cartagena", "Cucuta"],
    countryReds: ["Guyana", "Venezuela", "Ecuador", "Brunei", "Liberia"],
    flagPath: "Colombia.png",
  },
  {
    country: "Ecuador",
    capital: "Quito",
    capitalReds: [
      "Guayaquil",
      "Cuenca",
      "Santo Domingo de los Colorados",
      "Machala",
      "Manta",
    ],
    countryReds: ["Peru", "Guyana", "Paraguay", "Rwanda", "Eritrea"],
    flagPath: "Ecuador.png",
  },
  {
    country: "Guyana",
    capital: "Georgetown",
    capitalReds: ["New Amsterdam", "Linden", "Bartica", "Lethem", "Mabaruma"],
    countryReds: ["Brazil", "Chile", "Argentina", "Libya", "Malawi"],
    flagPath: "Guyana.png",
  },
  {
    country: "Paraguay",
    capital: "Asuncion",
    capitalReds: [
      "San Lorenzo",
      "Ciudad del Este",
      "Encarnacion",
      "Pedro Juan Caballero",
      "Coronel Oviedo",
    ],
    countryReds: [
      "Venezuela",
      "Guyana",
      "Colombia",
      "Eswatini",
      "Central African Republic",
    ],
    flagPath: "Paraguay.png",
  },
  {
    country: "Peru",
    capital: "Lima",
    capitalReds: ["Callao", "Arequipa", "Trujillo", "Chiclayo", "Chimbote"],
    countryReds: ["Chile", "Brazil", "Paraguay", "Russia", "Mauritania"],
    flagPath: "Peru.png",
  },
  {
    country: "Suriname",
    capital: "Paramaribo",
    capitalReds: [
      "Nieuw Nickerie",
      "Brokopondo",
      "Albina",
      "Nieuw Amsterdam",
      "Groningen",
    ],
    countryReds: ["Venezuela", "Colombia", "Peru", "Bahrain", "Italy"],
    flagPath: "Suriname.png",
  },
  {
    country: "Uruguay",
    capital: "Montevideo",
    capitalReds: [
      "Punta del Este",
      "Salto",
      "Ciudad de la Costa",
      "Rivera",
      "Paysandu",
    ],
    countryReds: ["Argentina", "Venezuela", "Colombia", "Moldova", "Senegal"],
    flagPath: "Uruguay.png",
  },
  {
    country: "Venezuela",
    capital: "Caracas",
    capitalReds: [
      "Maracaibo",
      "Valencia",
      "Barquisimeto",
      "Barinas",
      "Ciudad Guayana",
    ],
    countryReds: ["Uruguay", "Suriname", "Bolivia", "Tuvalu", "Jordan"],
    flagPath: "Venezuela.png",
  },
];

export const oceaniaRecordsArray: Record[] = [
  {
    country: "Australia",
    capital: "Canberra",
    capitalReds: ["Melbourne", "Brisbane", "Perth", "Adelaide", "Sydney"],
    countryReds: [
      "Solomon Islands",
      "Tonga",
      "New Zealand",
      "Portugal",
      "Dominica",
    ],
    flagPath: "Australia.png",
  },
  {
    country: "Fiji",
    capital: "Suva",
    capitalReds: ["Lautoka", "Nausori", "Nadi", "Labasa", "Ba"],
    countryReds: [
      "Solomon Islands",
      "Marshall Islands",
      "Vanuatu",
      "Mexico",
      "Guinea",
    ],
    flagPath: "Fiji.png",
  },
  {
    country: "Kiribati",
    capital: "Tarawa",
    capitalReds: ["Teaoraereke", "Tsumeb", "Otavi", "Buota", "Otaci"],
    countryReds: ["Solomon Islands", "Tuvalu", "Nauru", "Latvia", "Nepal"],
    flagPath: "Kiribati.png",
  },
  {
    country: "Marshall Islands",
    capital: "Majuro",
    capitalReds: ["Codru", "Cantemir", "Camenca", "Leova", "Briceni"],
    countryReds: ["Fiji", "Samoa", "Tuvalu", "Guinea-Bissau", "Argentina"],
    flagPath: "Marshall_Islands.png",
  },
  {
    country: "Micronesia",
    capital: "Palikir",
    capitalReds: ["Omaruru", "Rundu", "Te Anau", "Rivas", "Corinto"],
    countryReds: ["Samoa", "Fiji", "Solomon Islands", "Georgia", "Croatia"],
    flagPath: "Micronesia.png",
  },
  {
    country: "Nauru",
    capital: "Yaren District",
    capitalReds: ["Dosso", "Maradi", "Lokoja", "Bama", "Lafia"],
    countryReds: ["Samoa", "Fiji", "Australia", "Ghana", "Uruguay"],
    flagPath: "Nauru.png",
  },
  {
    country: "New Zealand",
    capital: "Wellington",
    capitalReds: [
      "Auckland",
      "Christchurch",
      "Manukau City",
      "Northcote",
      "Waitakere",
    ],
    countryReds: ["Palau", "Samoa", "Nauru", "Indonesia", "Laos"],
    flagPath: "New_Zealand.png",
  },
  {
    country: "Palau",
    capital: "Ngerulmud",
    capitalReds: ["Koror", "Melekeok", "Nguru", "Sapele", "Orlu"],
    countryReds: ["Nauru", "Kiribati", "Micronesia", "Afghanistan", "Egypt"],
    flagPath: "Palau.png",
  },
  {
    country: "Papua New Guinea",
    capital: "Port Moresby",
    capitalReds: ["Lae", "Madang", "Mount Hagen", "Popondetta", "Mendi"],
    countryReds: ["Vanuatu", "Kiribati", "Australia", "Libya", "Paraguay"],
    flagPath: "Papua_New_Guinea.png",
  },
  {
    country: "Samoa",
    capital: "Apia",
    capitalReds: [
      "Afega",
      "Leulumoega",
      "Mulifanua",
      "Lufilufi",
      "Safotulafai",
    ],
    countryReds: ["Kiribati", "Vanuatu", "Tuvalu", "Finland", "Poland"],
    flagPath: "Samoa.png",
  },
  {
    country: "Solomon Islands",
    capital: "Honiara",
    capitalReds: ["Gizo", "Auki", "Kirakira", "Taro", "Lata"],
    countryReds: [
      "Vanuatu",
      "Papua New Guinea",
      "Marshall Islands",
      "Dominican Republic",
      "Jamaica",
    ],
    flagPath: "Solomon_Islands.png",
  },
  {
    country: "Tonga",
    capital: "Nuku'alofa",
    capitalReds: ["Neiafu", "Samamea", "Satupa'itea", "Asau", "Safotu"],
    countryReds: ["Australia", "Vanuatu", "New Zealand", "India", "Latvia"],
    flagPath: "Tonga.png",
  },
  {
    country: "Tuvalu",
    capital: "Funafuti",
    capitalReds: ["Vailoa", "Saleaula", "Sigatoka", "Wewak", "Alotau"],
    countryReds: [
      "Australia",
      "Vanuatu",
      "Fiji",
      "Saint Lucia",
      "Saint Kitts and Nevis",
    ],
    flagPath: "Tuvalu.png",
  },
  {
    country: "Vanuatu",
    capital: "Port Vila",
    capitalReds: ["Luganville", "Lakatoro", "Isangel", "Saratamata", "Sola"],
    countryReds: ["Kiribati", "Fiji", "Micronesia", "Russia", "Brazil"],
    flagPath: "Vanuatu.png",
  },
];

export const usaRecordsArray: Record[] = [
  {
    country: "Alabama",
    capital: "Montgomery",
    capitalReds: ["Huntsville", "Birmingham", "Hoover", "Tuscaloosa", "Mobile"],
    countryReds: [
      "Kentucky",
      "North Carolina",
      "Mississippi",
      "Delaware",
      "Massachusetts",
    ],
    flagPath: "Alabama.png",
  },
  {
    country: "Alaska",
    capital: "Juneau",
    capitalReds: ["Anchorage", "Fairbanks", "Wasilla", "Sitka", "Kenai"],
    countryReds: [
      "Massachusetts",
      "New Mexico",
      "California",
      "Maine",
      "Nebraska",
    ],
    flagPath: "Alaska.png",
  },
  {
    country: "Arizona",
    capital: "Phoenix",
    capitalReds: ["Tucson", "Mesa", "Chandler", "Gilbert", "Glendale"],
    countryReds: ["Rhode Island", "Washington", "Maine", "New Jersey", "Utah"],
    flagPath: "Arizona.png",
  },
  {
    country: "Arkansas",
    capital: "Little Rock",
    capitalReds: [
      "Fayetteville",
      "Fort Smith",
      "Springdale",
      "Jonesboro",
      "Rogers",
    ],
    countryReds: [
      "Louisiana",
      "Georgia",
      "Virginia",
      "North Carolina",
      "New Jersey",
    ],
    flagPath: "Arkansas.png",
  },
  {
    country: "California",
    capital: "Sacramento",
    capitalReds: [
      "Los Angeles",
      "San Diego",
      "San Jose",
      "San Francisco",
      "Fresno",
    ],
    countryReds: ["Washington", "Massachusetts", "Mississippi", "Utah", "Iowa"],
    flagPath: "California.png",
  },
  {
    country: "Colorado",
    capital: "Denver",
    capitalReds: [
      "Colorado Springs",
      "Aurora",
      "Fort Collins",
      "Lakewood",
      "Thornton",
    ],
    countryReds: [
      "Texas",
      "Kentucky",
      "Oregon",
      "Rhode Island",
      "West Virginia",
    ],
    flagPath: "Colorado.png",
  },
  {
    country: "Connecticut",
    capital: "Hartford",
    capitalReds: [
      "Bridgeport",
      "Stamford",
      "New Haven",
      "Waterbury",
      "Norwalk",
    ],
    countryReds: ["Michigan", "Kansas", "Missouri", "Arkansas", "Vermont"],
    flagPath: "Connecticut.png",
  },
  {
    country: "Delaware",
    capital: "Dover",
    capitalReds: ["Wilmington", "Newark", "Middletown", "Bear", "Glasgow"],
    countryReds: ["Nevada", "Alaska", "South Carolina", "South Dakota", "Ohio"],
    flagPath: "Delaware.png",
  },
  {
    country: "Florida",
    capital: "Tallahassee",
    capitalReds: [
      "Jacksonville",
      "Miami",
      "Tampa",
      "Orlando",
      "St. Petersburg",
    ],
    countryReds: ["New Jersey", "Texas", "Maryland", "West Virginia", "Ohio"],
    flagPath: "Florida.png",
  },
  {
    country: "Georgia",
    capital: "Atlanta",
    capitalReds: [
      "Columbus",
      "Savannah",
      "Sandy Springs",
      "Macon-Bibb County",
      "Roswell",
    ],
    countryReds: [
      "Wisconsin",
      "Pennsylvania",
      "Tennessee",
      "Louisiana",
      "Delaware",
    ],
    flagPath: "Georgia_State.png",
  },
  {
    country: "Hawaii",
    capital: "Honolulu",
    capitalReds: ["Pearl City", "Hilo", "Waipahu", "Kaneohe", "Kahului"],
    countryReds: [
      "New Mexico",
      "Arkansas",
      "Virginia",
      "Michigan",
      "Washington",
    ],
    flagPath: "Hawaii.png",
  },
  {
    country: "Idaho",
    capital: "Boise",
    capitalReds: ["Meridian", "Nampa", "Idaho Falls", "Caldwell", "Pocatello"],
    countryReds: [
      "West Virginia",
      "Alaska",
      "North Carolina",
      "Mississippi",
      "Oregon",
    ],
    flagPath: "Idaho.png",
  },
  {
    country: "Illinois",
    capital: "Springfield",
    capitalReds: ["Chicago", "Aurora", "Joliet", "Naperville", "Rockford"],
    countryReds: [
      "Mississippi",
      "Florida",
      "Louisiana",
      "Wisconsin",
      "Missouri",
    ],
    flagPath: "Illinois.png",
  },
  {
    country: "Indiana",
    capital: "Indianapolis",
    capitalReds: [
      "Fort Wayne",
      "Evansville",
      "South Bend",
      "Carmel",
      "Fishers",
    ],
    countryReds: [
      "South Carolina",
      "Oklahoma",
      "Michigan",
      "North Carolina",
      "Alabama",
    ],
    flagPath: "Indiana.png",
  },
  {
    country: "Iowa",
    capital: "Des Moines",
    capitalReds: [
      "Cedar Rapids",
      "Davenport",
      "Sioux City",
      "Iowa City",
      "Waterloo",
    ],
    countryReds: ["Michigan", "Utah", "Vermont", "Tennessee", "Arizona"],
    flagPath: "Iowa.png",
  },
  {
    country: "Kansas",
    capital: "Topeka",
    capitalReds: [
      "Wichita",
      "Overland Park",
      "Kansas City",
      "Olathe",
      "Lawrence",
    ],
    countryReds: [
      "Maryland",
      "Washington",
      "Iowa",
      "New York",
      "North Carolina",
    ],
    flagPath: "Kansas.png",
  },
  {
    country: "Kentucky",
    capital: "Frankfort",
    capitalReds: [
      "Louisville",
      "Lexington-Fayette",
      "Bowling Green",
      "Georgetown",
      "Richmond",
    ],
    countryReds: [
      "Georgia",
      "North Dakota",
      "Connecticut",
      "Arizona",
      "South Dakota",
    ],
    flagPath: "Kentucky.png",
  },
  {
    country: "Louisiana",
    capital: "Baton Rouge",
    capitalReds: [
      "New Orleans",
      "Shreveport",
      "Metairie",
      "Lafayette",
      "Lake Charles",
    ],
    countryReds: ["Hawaii", "New York", "Oklahoma", "New Mexico", "Nevada"],
    flagPath: "Louisiana.png",
  },
  {
    country: "Maine",
    capital: "Augusta",
    capitalReds: ["Portland", "Lewiston", "Bangor", "Auburn", "Biddeford"],
    countryReds: ["Wisconsin", "New Mexico", "Montana", "Arkansas", "Maryland"],
    flagPath: "Maine.png",
  },
  {
    country: "Maryland",
    capital: "Annapolis",
    capitalReds: [
      "Baltimore",
      "Columbia",
      "Germantown",
      "Waldorf",
      "Silver Spring",
    ],
    countryReds: [
      "New Hampshire",
      "Tennessee",
      "Maine",
      "Connecticut",
      "Delaware",
    ],
    flagPath: "Maryland.png",
  },
  {
    country: "Massachusetts",
    capital: "Boston",
    capitalReds: [
      "Worcester",
      "Springfield",
      "Cambridge",
      "Lowell",
      "Brockton",
    ],
    countryReds: [
      "California",
      "Oregon",
      "Arizona",
      "South Dakota",
      "New York",
    ],
    flagPath: "Massachusetts.png",
  },
  {
    country: "Michigan",
    capital: "Lansing",
    capitalReds: [
      "Detroit",
      "Sterling Heights",
      "Dearborn",
      "Canton",
      "Shelby",
    ],
    countryReds: [
      "Iowa",
      "Oregon",
      "West Virginia",
      "South Carolina",
      "Colorado",
    ],
    flagPath: "Michigan.png",
  },
  {
    country: "Minnesota",
    capital: "Saint Paul",
    capitalReds: [
      "Minneapolis",
      "Rochester",
      "Bloomington",
      "Duluth",
      "Brooklyn Park",
    ],
    countryReds: [
      "Pennsylvania",
      "Indiana",
      "Florida",
      "New Hampshire",
      "Alaska",
    ],
    flagPath: "Minnesota.png",
  },
  {
    country: "Mississippi",
    capital: "Jackson",
    capitalReds: [
      "Gulfport",
      "Southaven",
      "Biloxi",
      "Hattiesburg",
      "Olive Branch",
    ],
    countryReds: ["Maryland", "Louisiana", "Kentucky", "Ohio", "Florida"],
    flagPath: "Mississippi.png",
  },
  {
    country: "Missouri",
    capital: "Jefferson City",
    capitalReds: [
      "Kansas City",
      "St. Louis",
      "Springfield",
      "Columbia",
      "Independence",
    ],
    countryReds: [
      "North Dakota",
      "Connecticut",
      "New Hampshire",
      "Delaware",
      "Virginia",
    ],
    flagPath: "Missouri.png",
  },
  {
    country: "Montana",
    capital: "Helena",
    capitalReds: [
      "Billings",
      "Missoula",
      "Great Falls",
      "Bozeman",
      "Butte-Silver Bow",
    ],
    countryReds: [
      "South Dakota",
      "Minnesota",
      "Rhode Island",
      "Indiana",
      "Colorado",
    ],
    flagPath: "Montana.png",
  },
  {
    country: "Nebraska",
    capital: "Lincoln",
    capitalReds: ["Omaha", "Bellevue", "Grand Island", "Kearney", "Hastings"],
    countryReds: ["Hawaii", "Alabama", "Illinois", "Delaware", "Florida"],
    flagPath: "Nebraska.png",
  },
  {
    country: "Nevada",
    capital: "Carson City",
    capitalReds: [
      "Las Vegas",
      "Henderson",
      "Reno",
      "Enterprise",
      "Spring Valley",
    ],
    countryReds: [
      "Tennessee",
      "Alaska",
      "Maryland",
      "Massachusetts",
      "Nebraska",
    ],
    flagPath: "Nevada.png",
  },
  {
    country: "New Hampshire",
    capital: "Concord",
    capitalReds: ["Manchester", "Nashua", "Derry", "Dover", "Rochester"],
    countryReds: ["Iowa", "Arizona", "Colorado", "Nevada", "Alabama"],
    flagPath: "New_Hampshire.png",
  },
  {
    country: "New Jersey",
    capital: "Trenton",
    capitalReds: ["Newark", "Jersey City", "Paterson", "Elizabeth", "Edison"],
    countryReds: ["Tennessee", "Alaska", "West Virginia", "Iowa", "Indiana"],
    flagPath: "New_Jersey.png",
  },
  {
    country: "New Mexico",
    capital: "Santa Fe",
    capitalReds: [
      "Albuquerque",
      "Las Cruces",
      "Rio Rancho",
      "Roswell",
      "Farmington",
    ],
    countryReds: [
      "Georgia",
      "Mississippi",
      "Maine",
      "Connecticut",
      "North Carolina",
    ],
    flagPath: "New_Mexico.png",
  },
  {
    country: "New York",
    capital: "Albany",
    capitalReds: ["Hempstead", "New York", "Brookhaven", "Islip", "Buffalo"],
    countryReds: [
      "California",
      "New Jersey",
      "Kentucky",
      "Massachusetts",
      "Georgia",
    ],
    flagPath: "New_York.png",
  },
  {
    country: "North Carolina",
    capital: "Raleigh",
    capitalReds: [
      "Charlotte",
      "Greensboro",
      "Durham",
      "Winston-Salem",
      "Fayetteville",
      "Cary",
    ],
    countryReds: ["Hawaii", "Kansas", "Colorado", "Arkansas", "Virginia"],
    flagPath: "North_Carolina.png",
  },
  {
    country: "North Dakota",
    capital: "Bismarck",
    capitalReds: ["Fargo", "Grand Forks", "Minot", "Williston", "Dickinson"],
    countryReds: [
      "Colorado",
      "North Carolina",
      "Virginia",
      "Louisiana",
      "Minnesota",
    ],
    flagPath: "North_Dakota.png",
  },
  {
    country: "Ohio",
    capital: "Columbus",
    capitalReds: ["Cleveland", "Cincinnati", "Toledo", "Akron", "Dayton"],
    countryReds: ["Indiana", "Illinois", "Nebraska", "Arkansas", "New York"],
    flagPath: "Ohio.png",
  },
  {
    country: "Oklahoma",
    capital: "Oklahoma City",
    capitalReds: ["Tulsa", "Norman", "Broken Arrow", "Edmond", "Lawton"],
    countryReds: ["Pennsylvania", "Arizona", "Ohio", "Oregon", "New York"],
    flagPath: "Oklahoma.png",
  },
  {
    country: "Oregon",
    capital: "Salem",
    capitalReds: ["Portland", "Eugene", "Gresham", "Hillsboro", "Bend"],
    countryReds: ["Vermont", "New York", "Georgia", "New Jersey", "Wisconsin"],
    flagPath: "Oregon.png",
  },
  {
    country: "Pennsylvania",
    capital: "Harrisburg",
    capitalReds: [
      "Philadelphia",
      "Pittsburgh",
      "Allentown",
      "Erie",
      "Scranton",
    ],
    countryReds: [
      "Nebraska",
      "South Carolina",
      "Connecticut",
      "Louisiana",
      "Rhode Island",
    ],
    flagPath: "Pennsylvania.png",
  },
  {
    country: "Rhode Island",
    capital: "Providence",
    capitalReds: [
      "Cranston",
      "Warwick",
      "Pawtucket",
      "Woonsocket",
      "Cumberland",
    ],
    countryReds: ["Washington", "Alaska", "Connecticut", "Hawaii", "Illinois"],
    flagPath: "Rhode_Island.png",
  },
  {
    country: "South Carolina",
    capital: "Columbia",
    capitalReds: [
      "Charleston",
      "Mount Pleasant",
      "Rock Hill",
      "Greenville",
      "Summerville",
    ],
    countryReds: ["South Dakota", "Texas", "Alabama", "Kansas", "Nevada"],
    flagPath: "South_Carolina.png",
  },
  {
    country: "South Dakota",
    capital: "Pierre",
    capitalReds: [
      "Sioux Falls",
      "Rapid City",
      "Aberdeen",
      "Brookings",
      "Watertown",
    ],
    countryReds: [
      "Oregon",
      "Florida",
      "Pennsylvania",
      "South Carolina",
      "Arizona",
    ],
    flagPath: "South_Dakota.png",
  },
  {
    country: "Tennessee",
    capital: "Nashville",
    capitalReds: [
      "Memphis",
      "Knoxville",
      "Chattanooga",
      "Clarksville",
      "Franklin",
    ],
    countryReds: [
      "Pennsylvania",
      "Michigan",
      "Oregon",
      "Rhode Island",
      "Kentucky",
    ],
    flagPath: "Tennessee.png",
  },
  {
    country: "Texas",
    capital: "Austin",
    capitalReds: ["Houston", "San Antonio", "Dallas", "Fort Worth", "El Paso"],
    countryReds: ["Oregon", "Vermont", "Nebraska", "North Dakota", "Louisiana"],
    flagPath: "Texas.png",
  },
  {
    country: "Utah",
    capital: "Salt Lake City",
    capitalReds: ["West Valley City", "West Jordan", "Provo", "Orem", "Sandy"],
    countryReds: ["Ohio", "New York", "Texas", "Oklahoma", "Colorado"],
    flagPath: "Utah.png",
  },
  {
    country: "Vermont",
    capital: "Montpelier",
    capitalReds: ["Burlington", "Essex", "Colchester", "Bennington", "Milton"],
    countryReds: ["Alabama", "Montana", "Maryland", "New York", "Maine"],
    flagPath: "Vermont.png",
  },
  {
    country: "Virginia",
    capital: "Richmond",
    capitalReds: [
      "Virginia Beach",
      "Chesapeake",
      "Arlington",
      "Norfolk",
      "Alexandria",
    ],
    countryReds: ["Tennessee", "Alabama", "Louisiana", "Kansas", "Vermont"],
    flagPath: "Virginia.png",
  },
  {
    country: "Washington",
    capital: "Olympia",
    capitalReds: ["Seattle", "Spokane", "Tacoma", "Vancouver", "Bellevue"],
    countryReds: ["Indiana", "Louisiana", "Arizona", "Nebraska", "Missouri"],
    flagPath: "Washington.png",
  },
  {
    country: "West Virginia",
    capital: "Charleston",
    capitalReds: [
      "Huntington",
      "Morgantown",
      "Parkersburg",
      "Wheeling",
      "Fairmont",
    ],
    countryReds: ["Georgia", "Alabama", "Kentucky", "Alaska", "Hawaii"],
    flagPath: "West_Virginia.png",
  },
  {
    country: "Wisconsin",
    capital: "Madison",
    capitalReds: ["Milwaukee", "Green Bay", "Kenosha", "Racine", "Appleton"],
    countryReds: ["Illinois", "Arkansas", "Minnesota", "Kansas", "Kentucky"],
    flagPath: "Wisconsin.png",
  },
  {
    country: "Wyoming",
    capital: "Cheyenne",
    capitalReds: ["Casper", "Gillette", "Laramie", "Rock Springs", "Sheridan"],
    countryReds: [
      "California",
      "South Carolina",
      "Oklahoma",
      "Indiana",
      "Missouri",
    ],
    flagPath: "Wyoming.png",
  },
];

export const theMap: Map<string, Record[]> = new Map<string, Record[]>([
  [
    "THE ENTIRE WORLD",
    [
      ...europeRecordsArray,
      ...asiaRecordsArray,
      ...africaRecordsArray,
      ...northAmericaRecordsArray,
      ...southAmericaRecordsArray,
      ...oceaniaRecordsArray,
    ],
  ],
  ["EUROPE", europeRecordsArray],
  ["ASIA", asiaRecordsArray],
  ["AFRICA", africaRecordsArray],
  ["NORTH AMERICA", northAmericaRecordsArray],
  ["SOUTH AMERICA", southAmericaRecordsArray],
  ["OCEANIA", oceaniaRecordsArray],
  ["USA", usaRecordsArray],
]);
