export const payloadMessage = new Map<string, string>([
  ["COUNTRY CAPITALS", "CapitalsSet"],
  ["USA STATE CAPITALS", "CapitalsSet"],
  ["COUNTRY TO CAPITAL", "OrderOfQuestions"],
  ["STATE TO CAPITAL", "OrderOfQuestions"],
  ["CAPITAL TO COUNTRY", "OrderOfQuestions"],
  ["CAPITAL TO STATE", "OrderOfQuestions"],
  ["MULTIPLE CHOICE", "QuizType"],
  ["WRITTEN ANSWERS", "QuizType"],
  ["THE ENTIRE WORLD", "ContinentsSet"],
  ["EUROPE", "ContinentsSet"],
  ["AFRICA", "ContinentsSet"],
  ["OCEANIA", "ContinentsSet"],
  ["ASIA", "ContinentsSet"],
  ["NORTH AMERICA", "ContinentsSet"],
  ["SOUTH AMERICA", "ContinentsSet"],
]);

export const payloadDelivery = new Map<string, string>([
  ["COUNTRY CAPITALS", "WORLD"],
  ["USA STATE CAPITALS", "USA"],
  ["COUNTRY TO CAPITAL", "TO_CAPITAL"],
  ["STATE TO CAPITAL", "TO_CAPITAL"],
  ["CAPITAL TO COUNTRY", "CAPITAL_TO"],
  ["CAPITAL TO STATE", "CAPITAL_TO"],
  ["MULTIPLE CHOICE", "MULTI"],
  ["WRITTEN ANSWERS", "WRITTEN"],
  ["THE ENTIRE WORLD", "THE ENTIRE WORLD"],
  ["EUROPE", "EUROPE"],
  ["AFRICA", "AFRICA"],
  ["OCEANIA", "OCEANIA"],
  ["ASIA", "ASIA"],
  ["NORTH AMERICA", "NORTH AMERICA"],
  ["SOUTH AMERICA", "SOUTH AMERICA"],
]);
