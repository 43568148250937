import { createSlice, configureStore, combineReducers } from "@reduxjs/toolkit";
import { theMap } from "../data/capitals_data";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { shuffle, shuffleReds } from "../util/arrayShuffle";

const settingsSlice = createSlice({
  name: "gameSettings",
  initialState: {
    capitalsSet: "UNSELECTED",
    orderOfQuestions: "UNSELECTED",
    quizType: "UNSELECTED",
    rowTwoReveal: false,
    rowThreeReveal: false,
    continueButtonReveal: false,
    continentsSet: ["THE ENTIRE WORLD"],
  },
  reducers: {
    optionsSet(state, action) {
      if (action.payload.message === "CapitalsSet") {
        state.capitalsSet = action.payload.delivery;
        state.rowTwoReveal = true;
      }
      if (action.payload.message === "OrderOfQuestions") {
        state.orderOfQuestions = action.payload.delivery;
        state.rowThreeReveal = true;
      }
      if (action.payload.message === "QuizType") {
        state.quizType = action.payload.delivery;
        state.continueButtonReveal = true;
      }
      if (action.payload.message === "ContinentsSet") {
        if (state.continentsSet.includes(action.payload.delivery)) {
          if (action.payload.delivery !== "THE ENTIRE WORLD") {
            if (state.continentsSet.length > 1) {
              const index = state.continentsSet.indexOf(
                action.payload.delivery
              );
              state.continentsSet.splice(index, 1);
            } else {
              state.continentsSet = [action.payload.delivery];
            }
          } else {
            state.continentsSet = ["THE ENTIRE WORLD"];
          }
        } else {
          if (action.payload.delivery === "THE ENTIRE WORLD") {
            state.continentsSet = ["THE ENTIRE WORLD"];
          } else {
            if (state.continentsSet.includes("THE ENTIRE WORLD")) {
              state.continentsSet = [action.payload.delivery];
            } else if (state.continentsSet.length === 5) {
              state.continentsSet = ["THE ENTIRE WORLD"];
            } else {
              state.continentsSet.push(action.payload.delivery);
            }
          }
        }
      }
    },
    revertState(state) {
      state.capitalsSet = "UNSELECTED";
      state.orderOfQuestions = "UNSELECTED";
      state.quizType = "UNSELECTED";
      state.rowTwoReveal = false;
      state.rowThreeReveal = false;
      state.continueButtonReveal = false;
      state.continentsSet = ["THE ENTIRE WORLD"];
    },
  },
});

const scoreSlice = createSlice({
  name: "gameScore",
  initialState: {
    questions: [
      {
        country: "default",
        capital: "default",
        capitalReds: [
          "default1",
          "default2",
          "default3",
          "default4",
          "default5",
        ],
        countryReds: [
          "default1",
          "default2",
          "default3",
          "default4",
          "default5",
        ],
        flagPath: "Albania.png",
      },
    ],
    questionsCount: 0,
    currentQuestion: {
      country: "default",
      capital: "default",
      capitalReds: ["default1", "default2", "default3", "default4", "default5"],
      countryReds: ["default1", "default2", "default3", "default4", "default5"],
      flagPath: "Albania.png",
    },
    correctAnswerCount: 0,
    incorrectAnswerCount: 0,
    correctAnswers: [{ country: "default", capital: "default" }],
    incorrectAnswers: [
      { country: "default", capital: "default", incorrectAnswer: "default" },
    ],
    completionTime: 0,
    currentTextInput: "",
    writtenBoxColour: "bg-white",
    showCorrectAnswer: "",
    multiButtonLock: false,
    showFlag: true,
    showTick: false,
  },
  reducers: {
    gameDataPort(state, action) {
      const continents =
        action.payload.message === "continents"
          ? action.payload.continents
          : ["USA"];
      var arrayForSort: any[] = [];
      for (var index = 0; index < continents.length; index++) {
        const toPush = theMap.get(continents[index]);
        if (toPush) {
          arrayForSort = [...arrayForSort, ...toPush];
        }
      }
      state.questions = shuffle(shuffleReds(arrayForSort));
      state.questionsCount = state.questions.length;
    },
    setCurrentQuestion(state, action) {
      const currentQuestion = action.payload.currentQuestion;
      state.currentQuestion = currentQuestion;
    },
    addToCorrectAnswers(state, action) {
      if (state.correctAnswers[0].country === "default") {
        state.correctAnswers = [action.payload];
      } else {
        state.correctAnswers.push(action.payload);
      }
      const indexOfCurrent = state.questions.findIndex(
        (question) => question.country === action.payload.country
      );
      state.questions.splice(indexOfCurrent, 1);
      state.correctAnswerCount++;
    },
    addToIncorrectAnswers(state, action) {
      if (state.incorrectAnswers[0].country === "default") {
        state.incorrectAnswers = [action.payload];
      } else {
        state.incorrectAnswers.push(action.payload);
      }
      const indexOfCurrent = state.questions.findIndex(
        (question) => question.country === action.payload.country
      );
      state.questions.splice(indexOfCurrent, 1);
      state.incorrectAnswerCount++;
    },
    updateCompletionTime(state) {
      state.completionTime++;
    },
    updateCurrentTextInput(state, action) {
      state.currentTextInput = action.payload;
    },
    writtenBoxColourUpdate(state, action) {
      state.writtenBoxColour = action.payload;
    },
    showCorrectAnswer(state, action) {
      state.showCorrectAnswer = action.payload;
    },
    updateMultiButtonLock(state, action) {
      state.multiButtonLock = action.payload;
    },
    revertState(state) {
      state.currentQuestion = {
        country: "default",
        capital: "default",
        capitalReds: [
          "default1",
          "default2",
          "default3",
          "default4",
          "default5",
        ],
        countryReds: [
          "default1",
          "default2",
          "default3",
          "default4",
          "default5",
        ],
        flagPath: "Albania.png",
      };
      state.questions = [state.currentQuestion];
      state.questionsCount = 0;
      state.correctAnswerCount = 0;
      state.incorrectAnswerCount = 0;
      state.correctAnswers = [{ country: "default", capital: "default" }];
      state.incorrectAnswers = [
        { country: "default", capital: "default", incorrectAnswer: "default" },
      ];
      state.completionTime = 0;
      state.currentTextInput = "";
      state.writtenBoxColour = "bg-white";
      state.showCorrectAnswer = "";
    },
    toggleFlag(state) {
      if (state.showFlag === true) {
        state.showFlag = false;
      } else {
        state.showFlag = true;
      }
    },
    showTick(state, action) {
      state.showTick = action.payload;
    },
  },
});

const scoreSliceReducer = scoreSlice.reducer;
const settingsSliceReducer = settingsSlice.reducer;
const reducers = combineReducers({ scoreSliceReducer, settingsSliceReducer });

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const settingsActions = settingsSlice.actions;
export const scoreActions = scoreSlice.actions;

export type RootState = ReturnType<typeof store.getState>;

export default store;
