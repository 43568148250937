export function shuffle(array: any[]) {
  var arrayToReturn = [...array];
  let currentIndex = arrayToReturn.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [arrayToReturn[currentIndex], arrayToReturn[randomIndex]] = [
      arrayToReturn[randomIndex],
      arrayToReturn[currentIndex],
    ];
  }

  return arrayToReturn;
}

export function shuffleReds(array: any[]) {
  var arrayToReturn = [...array];
  for (var i = 0; i < arrayToReturn.length; i++) {
    shuffle(arrayToReturn[i].capitalReds);
    shuffle(arrayToReturn[i].countryReds);
  }
  return arrayToReturn;
}
