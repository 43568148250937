import React, { Suspense } from "react";
import "./App.css";
import Home from "./views/Home";
import { Route, Routes } from "react-router-dom";
import { RingLoader } from "react-spinners";

const MainMenu = React.lazy(() => import("./views/MainMenu"));
const ContinentsMenu = React.lazy(() => import("./views/ContinentsMenu"));
const MultiChoiceQuiz = React.lazy(() => import("./views/MultiChoiceQuiz"));
const WrittenQuiz = React.lazy(() => import("./views/WrittenQuiz"));
const Results = React.lazy(() => import("./views/Results"));
const Breakdown = React.lazy(() => import("./views/Breakdown"));

function App() {
  return (
    <Suspense
      fallback={
        <div className="flex-center text-center mt-[10vw] mr-[10vw]">
          <RingLoader color="white" size={"10vw"} />
        </div>
      }
    >
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/MainMenu" element={<MainMenu />} />
        <Route path="/ContinentsMenu" element={<ContinentsMenu />} />
        <Route path="/MultiChoiceQuiz" element={<MultiChoiceQuiz />} />
        <Route path="/WrittenQuiz" element={<WrittenQuiz />} />
        <Route path="/Results" element={<Results />} />
        <Route path="/Breakdown" element={<Breakdown />} />
      </Routes>
    </Suspense>
  );
}

export default App;
