import React from "react";
import Globe from "react-globe.gl";
import { GlobeMethods } from "react-globe.gl";
import * as THREE from "three";
import { useEffect, useRef } from "react";

type globeControls = {
  autoRotate: boolean;
  autoRotateSpeed: number;
  enableZoom: boolean;
};

const globeMaterial = new THREE.MeshPhongMaterial();
globeMaterial.bumpScale = 30;
new THREE.TextureLoader().load(
  "//unpkg.com/three-globe/example/img/earth-water.png",
  (texture) => {
    globeMaterial.specularMap = texture;
    globeMaterial.specular = new THREE.Color("grey");
    globeMaterial.shininess = 15;
  }
);

const HomeGlobe: React.FC<{ globeMultiplier: number }> = ({
  globeMultiplier,
}) => {
  const globeEl = useRef<GlobeMethods | undefined>();

  useEffect(() => {
    const globe = globeEl.current;

    if (globe) {
      // Auto-rotate
      const controls = globe.controls() as globeControls;
      controls.enableZoom = false;
      controls.autoRotate = true;
      controls.autoRotateSpeed = 1.5;
    }
  }, []);

  return (
    <Globe
      ref={globeEl}
      globeMaterial={globeMaterial}
      globeImageUrl="//unpkg.com/three-globe/example/img/earth-day.jpg"
      bumpImageUrl="//unpkg.com/three-globe/example/img/earth-topology.png"
      backgroundColor="rgba(0,0,0,0)"
      width={globeMultiplier}
      height={globeMultiplier}
    />
  );
};

export default HomeGlobe;
