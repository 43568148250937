import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { classNames } from "../util/classNames";
import { Colour, Size, BType } from "../util/enumLibrary";
import { payloadMessage, payloadDelivery } from "../util/mapsLibrary";
import { Link } from "react-router-dom";
import { scoreActions, settingsActions } from "../redux/redux_store";
import { RootState } from "../redux/redux_store";
import useWindowDimension from "../util/useWindowDimensions";

const COLOUR_MAPS: Record<Colour, string> = {
  [Colour.YELLOW]: "bg-[#FFE16E]",
  [Colour.WHITE]: "bg-white",
};

const SIZE_MAPS: Record<Size, string> = {
  [Size.SMALL]: "w-[16vw]",
  [Size.MEDIUM]: "w-[18vw]",
  [Size.LARGE]: "w-[22vw]",
  [Size.XLARGE]: "w-[26vw] h-[4.5vw]",
  [Size.HMEDIUM]: "w-[50vw] h-[10vw]",
  [Size.HLARGE]: "w-[40vw] h-[9vw]",
  [Size.HXLARGE]: "w-[50vw] h-[10vw]",
};

const SelectButton: React.FC<{
  colour: Colour;
  size: Size;
  text: string;
  btype: BType;
  destination: string;
}> = ({ colour, size, text, btype, destination }) => {
  const { width, height } = useWindowDimension();
  const screenRatio = width / height;

  const buttonStyle =
    screenRatio >= 1
      ? "rounded-full text-[2vw] px-[2.2vw] py-[0.5vw]"
      : screenRatio > 0.65
      ? "rounded-full text-[3vw] px-[2.2vw] py-[0.5vw]"
      : "rounded-full text-[3.6vw] px-[2.2vw] py-[0.5vw]";
  const linkStyle =
    screenRatio >= 1
      ? "rounded-full text-[4vh] px-[2vw] py-[0.5vw]"
      : "rounded-full text-[3vh] px-[6vw] py-[1vw]";

  const borderBlack =
    screenRatio >= 1
      ? "border-[0.5vw] border-black"
      : "border-[0.8vw] border-black";
  const continentBorderBlack =
    screenRatio >= 1
      ? "border-[0.3vw] border-black"
      : "border-[0.8vw] border-black";

  const outline1 =
    useSelector(
      (state: RootState) => state.settingsSliceReducer.capitalsSet
    ) === payloadDelivery.get(text)
      ? borderBlack
      : "";
  const outline2 =
    useSelector(
      (state: RootState) => state.settingsSliceReducer.orderOfQuestions
    ) === payloadDelivery.get(text)
      ? borderBlack
      : "";
  const outline3 =
    useSelector((state: RootState) => state.settingsSliceReducer.quizType) ===
    payloadDelivery.get(text)
      ? borderBlack
      : "";

  const outlines = outline1 + outline2 + outline3;

  const continentOutline = useSelector(
    (state: RootState) => state.settingsSliceReducer.continentsSet
  ).includes(text)
    ? continentBorderBlack
    : "";

  const dispatch = useDispatch();
  const optionsSetHandler = () => {
    dispatch(
      settingsActions.optionsSet({
        message: payloadMessage.get(text),
        delivery: payloadDelivery.get(text),
      })
    );
  };

  const reversionHandler = () => {
    dispatch(settingsActions.revertState());
    dispatch(scoreActions.revertState());
  };

  if (btype === BType.LINK)
    return (
      <div onClick={reversionHandler}>
        <Link
          to={destination}
          className={classNames(linkStyle, COLOUR_MAPS[colour])}
        >
          {text}
        </Link>
      </div>
    );
  return (
    <div>
      <button
        onClick={optionsSetHandler}
        className={classNames(
          buttonStyle,
          COLOUR_MAPS[colour],
          SIZE_MAPS[size],
          outlines,
          continentOutline
        )}
      >
        {text}
      </button>
    </div>
  );
};

export default SelectButton;
