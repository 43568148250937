export enum Colour {
  YELLOW,
  WHITE,
}

export enum Size {
  SMALL,
  MEDIUM,
  LARGE,
  XLARGE,
  HMEDIUM,
  HLARGE,
  HXLARGE,
}

export enum BType {
  LINK,
  BUTTON,
}
