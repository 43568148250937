import React from "react";
import { ArcText } from "@arctext/react";
import SelectButton from "../components/SelectButton";
import { Colour, Size, BType } from "../util/enumLibrary";
import useWindowDimension from "../util/useWindowDimensions";
import HomeGlobe from "../components/HomeGlobe";

const Home: React.FC<{}> = () => {
  const { width, height } = useWindowDimension();
  const arcMultiplier = Math.round(height / 1.25);
  const globeMultiplier = arcMultiplier;
  const screenRatio = width / height;

  if (screenRatio >= 1) {
    return (
      <div className="h-full flex justify-center items-center flex-col">
        <div className="absolute">
          <ArcText
            className="text-white text-[5vh]"
            text="THE WORLD'S BEST CAPITALS QUIZ"
            width={arcMultiplier}
            characterWidth={6.2}
          />
        </div>
        <div className="absolute">
          <HomeGlobe globeMultiplier={globeMultiplier} />
        </div>
        <div className="absolute bottom-[8%]">
          <SelectButton
            colour={Colour.YELLOW}
            size={Size.LARGE}
            text="Come on in"
            btype={BType.LINK}
            destination="/MainMenu"
          />
        </div>
      </div>
    );
  } else if (screenRatio > 0.65) {
    return (
      <div className="h-full flex justify-center items-center flex-col">
        <div className="absolute">
          <ArcText
            className="text-white text-[4.5vh]"
            text="THE WORLD'S BEST CAPITALS QUIZ"
            width={arcMultiplier / 1.3}
            characterWidth={6.2}
          />
        </div>
        <div className="absolute">
          <HomeGlobe globeMultiplier={globeMultiplier / 1.3} />
        </div>
        <div className="absolute bottom-[15%]">
          <SelectButton
            colour={Colour.YELLOW}
            size={Size.HXLARGE}
            text="Come on in"
            btype={BType.LINK}
            destination="/MainMenu"
          />
        </div>
      </div>
    );
  } else if (screenRatio > 0.5) {
    return (
      <div className="h-full flex justify-center items-center flex-col">
        <div className="absolute">
          <ArcText
            className="text-white text-[4vh]"
            text="THE WORLD'S BEST CAPITALS QUIZ"
            width={arcMultiplier / 1.5}
            characterWidth={6.2}
          />
        </div>
        <div className="absolute">
          <HomeGlobe globeMultiplier={globeMultiplier / 1.5} />
        </div>
        <div className="absolute bottom-[15%]">
          <SelectButton
            colour={Colour.YELLOW}
            size={Size.HXLARGE}
            text="Come on in"
            btype={BType.LINK}
            destination="/MainMenu"
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="h-full flex justify-center items-center flex-col">
        <div className="absolute">
          <ArcText
            className="text-white text-[3vh]"
            text="THE WORLD'S BEST CAPITALS QUIZ"
            width={arcMultiplier / 2}
            characterWidth={6.2}
          />
        </div>
        <div className="absolute">
          <HomeGlobe globeMultiplier={globeMultiplier / 2} />
        </div>
        <div className="absolute bottom-[15%]">
          <SelectButton
            colour={Colour.YELLOW}
            size={Size.HXLARGE}
            text="Come on in"
            btype={BType.LINK}
            destination="/MainMenu"
          />
        </div>
      </div>
    );
  }
};

export default Home;
